import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import TableComponent from '../../components/tableComponent/tableComponent'
import { getUserColumns } from '../../columns/users'
import { AppStore } from '../../store/store'
import { usersTabs } from '../../constans/tabs/users'
import UsersForm from '../../components/usersForm'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const Users = () => {
  const queryClient = useQueryClient()

  const offset = AppStore.useState((s) => s.table.offset)

  const limit = AppStore.useState((s) => s.table.limit)

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const { data: usersList, isLoading } = useQuery(['users', offset, limit], () =>
    apiRequest(routesList.user).get(offset, limit, { order_by: '-id' }),
  )

  const modifiedUsersList = usersList?.items?.map((el) => ({ ...el, key: el.id }))

  const { mutate: deleteUser } = useMutation(['delete_user'], {
    mutationFn: (id) => apiRequest(routesList.user).delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Пользователь удален'
        s.notifications.type = 'success'
      })
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Не удалось удалить пользователя'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  return (
    <div>
      <TableComponent
        title='Пользователи'
        data={modifiedUsersList}
        total={usersList?.total}
        columns={getUserColumns(deleteUser)}
        isLoading={isLoading}
        limit={limit}
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
        tabs={usersTabs}
        form={
          <UsersForm
            closeDrawer={() => {
              setIsDrawerVisible(false)
            }}
          />
        }
        tabContent={(id, rowData) => {
          return (
            <UsersForm
              id={id}
              info={rowData}
              closeDrawer={() => {
                setIsDrawerVisible(false)
              }}
            />
          )
        }}
      />
    </div>
  )
}

export default Users
