import React, { useEffect, useState } from 'react'
import {
  Badge,
  Button,
  Card,
  Cascader,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Form,
  Row,
  Select,
  Typography,
} from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'
import { AppStore } from '../../store/store'
import { convertDateWithMoment, useSendNotifications } from '../../utils/hooks'
import { softKindListOptions } from '../../constans/optionsList/softKindOptions'
import { DownloadOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { JsonEditor as Editor } from 'jsoneditor-react'

const LicenseForm = ({ closeDrawer, id, info }) => {
  const { Panel } = Collapse
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const { mutate: addLicense } = useMutation(['add_license'], {
    mutationFn: (data) => apiRequest(routesList.license).add(data),
    onSuccess: async () => {
      closeDrawer()
      await queryClient.invalidateQueries('license_list')
      form.resetFields()

      useSendNotifications.success('Лицензия успешно добавлена')
    },
    onError: (error) => {
      useSendNotifications.error(error)
    },
  })

  const { mutate: updateLicense } = useMutation(['update_license'], {
    mutationFn: (payload) => apiRequest(routesList.license).update({ id, payload }),
    onSuccess: async () => {
      closeDrawer()
      await queryClient.invalidateQueries('license_list')

      useSendNotifications.success('Лицензия успешно обновлена')
    },
    onError: (error) => {
      useSendNotifications.error(error)
    },
  })

  const [jsonValue, setJsonValue] = useState({})

  const onChangeJSON = (e) => {
    setJsonValue(e)
  }
  // 282
  const onSubmit = (payload) => {
    const data = {
      ...payload,
      software_id,
      valid_to: `${payload.valid_to.$y}-${payload.valid_to.$M + 1}-${
        payload.valid_to.$D
      }`,
      fields: jsonValue,
    }

    if (!id) {
      addLicense(data)
    }

    // if (!!id) {
    //   updateLicense(payload)
    //   return
    // }
  }

  useEffect(() => {
    if (info) {
      const { created_at, updated_at, valid_to } = info

      const payload = { created_at, updated_at, valid_to }

      form.setFieldsValue(payload)
    }
  }, [info])

  const { data: client_list } = useQuery(['client_list'], () =>
    apiRequest(routesList.clients).get(),
  )

  const modifiedClientList = client_list?.items?.map((el) => ({
    value: el.id,
    label: el.name,
  }))

  const [gitLabId, setGitLabId] = useState(null)

  const [options, setOptions] = useState([])

  const [branchName, setBranchName] = useState(null)

  const [version, setVersion] = useState(null)

  const [productID, setProductID] = useState(null)

  // useQuery(['soft_list11'], () => apiRequest(routesList.software).get(0, 200), {
  //   onSuccess: (data) => {
  //     if (data) {
  //       setOptions(
  //         data?.items?.map((soft) => {
  //           return {
  //             label: soft.name,
  //             value: soft.gitlab_id,
  //             isLeaf: false,
  //           }
  //         }),
  //       )
  //     }
  //   },
  // })

  const {
    isLoading: isSoftwareLoading,
    isSuccess: isSoftwareSoftSuccess,
    data: softwareList,
  } = useQuery(
    ['soft_list', productID],
    () => apiRequest(`product/${productID}/${routesList.software}`).get(0, 200),
    {
      enabled: !!productID,
      onSuccess: (data) => {
        if (data) {
          setOptions(
            data?.map((soft) => {
              return {
                label: `${soft.branch}-${soft.name}-${soft.kind}-${soft.version}`,
                value: soft.id,
                // isLeaf: false,
              }
            }),
          )
        }
      },
    },
  )

  // useQuery(
  //   ['get_branches_list', gitLabId],
  //   () => apiRequest(`${routesList.software}/${gitLabId}/branches`).get(),
  //   {
  //     enabled: !!gitLabId,
  //     onSuccess: (data) => {
  //       if (data) {
  //         setOptions((oldValue) =>
  //           oldValue.map((item) => {
  //             return {
  //               ...item,
  //               children: data?.items.map((item1) => {
  //                 return {
  //                   label: item1.branch,
  //                   value: item1.branch,
  //                   children: [
  //                     {
  //                       value: 'Нет данных',
  //                       label: 'Нет данных',
  //                     },
  //                   ],
  //                 }
  //               }),
  //             }
  //           }),
  //         )
  //       }
  //     },
  //   },
  // )

  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

  // useQuery(
  //   ['get_versions_list', gitLabId, branchName],
  //   () => apiRequest(`/software/${gitLabId}/branches/${branchName}/versions`).get(),
  //   {
  //     enabled: !!gitLabId && !!branchName,
  //     onSuccess: (data) => {
  //       setOptions((oldValue) =>
  //         oldValue?.map((el) => {
  //           return {
  //             ...el,
  //             children: el.children?.map((el1) => {
  //               return {
  //                 ...el1,
  //                 children: data?.items
  //                   ?.map((el2) => {
  //                     setVersion(el2)
  //                     return {
  //                       label: el2.version,
  //                       value: JSON.stringify(el2),
  //                       isLeaf: true,
  //                     }
  //                   })
  //                   .sort((a, b) => collator.compare(b.label, a.label)),
  //               }
  //             }),
  //           }
  //         }),
  //       )
  //     },
  //   },
  // )

  const onChange = (valueArr, value) => {
    if (valueArr.length === 1) {
      set_software_id(value[0].value)
      // setGitLabId(valueArr[0])
      // setBranchName(null)
      return
    }
    //
    // if (valueArr.length >= 2) {
    //   setBranchName(valueArr[1])
    // }
    //
    // if (valueArr.length === 3) {
    //   setVersion(JSON.parse(valueArr[2]))
    // }
  }

  const [software_id, set_software_id] = useState(null)
  const { Title } = Typography

  const { data: productsList } = useQuery(['get_pr_list_select_units'], () =>
    apiRequest(routesList.product).get(),
  )

  const modifiedProductsList = productsList?.items?.map((el) => ({
    value: el?.id,
    label: el?.name,
  }))

  return (
    <Form
      name='add_license'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      <Form.Item
        label='Годен до'
        name='valid_to'
        rules={[
          {
            required: true,
            message: 'Выберите дату',
          },
        ]}
      >
        <DatePicker onChange={() => {}} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label='Клиент' name='client_id'>
        <Select placeholder='Выберите клиента' allowClear options={modifiedClientList} />
      </Form.Item>

      <Form.Item label='Продукт' name='product_id'>
        <Select
          placeholder='Выберите продукт'
          allowClear
          onChange={(value) => {
            setProductID(value)
          }}
          options={modifiedProductsList}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label='Софт'
        name='software_id'
        rules={[
          {
            required: true,
            message: 'Выберите софт',
          },
        ]}
      >
        <Cascader
          options={options}
          placeholder='Выберите софт'
          onChange={onChange}
          changeOnSelect
          style={{ width: '100%' }}
          // showSearch
          // searchValue={searchValue}
          // onSearch={onSearch}
        />
      </Form.Item>

      <Editor value={jsonValue} onChange={onChangeJSON} mode='text' />

      <>
        {version && <Title level={3}>Версия {version?.version}</Title>}

        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
          <Collapse>
            {version?.patches
              ?.map((el) => {
                return (
                  <Panel
                    extra={
                      <Button
                        style={{
                          backgroundColor:
                            el.builds[0].software_id === software_id
                              ? 'orange'
                              : 'transparent',
                          color:
                            el.builds[0].software_id === software_id ? 'white' : 'black',
                        }}
                        onClick={(e) => {
                          e.stopPropagation()

                          set_software_id(() => el.builds[0].software_id)
                        }}
                      >
                        Выбрать софт
                      </Button>
                    }
                    key={Math.random()}
                    header={`Патч № ${el.patch}`}
                  >
                    <div
                      style={{
                        display: 'grid',
                        gap: 20,
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}
                    >
                      {el?.builds?.map((el1, index1) => {
                        return (
                          <Col key={Math.random()}>
                            <Badge.Ribbon text={el1.target_os}>
                              <Card>
                                <Title level={2}>Билд № {index1 + 1}</Title>

                                <Descriptions column={1}>
                                  <Descriptions.Item label='Название'>
                                    {el1.name}
                                  </Descriptions.Item>

                                  <Descriptions.Item label='Тип пакеты'>
                                    {el1.package_type}
                                  </Descriptions.Item>

                                  <Descriptions.Item label='Дата создания'>
                                    {convertDateWithMoment(el1.created_at)}
                                  </Descriptions.Item>

                                  <Descriptions.Item label='Дата обновления'>
                                    {convertDateWithMoment(el1.updated_at)}
                                  </Descriptions.Item>
                                </Descriptions>
                              </Card>
                            </Badge.Ribbon>
                          </Col>
                        )
                      })}
                    </div>
                  </Panel>
                )
              })
              .sort((a, b) => collator.compare(b.patch, a.patch))
              .map((el, index, array) => {
                if (index + 1 === array.length) {
                  return el
                }
                return ''
              })}
          </Collapse>
        </div>
      </>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
        style={{ marginTop: 20 }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          <PlusSquareOutlined />
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LicenseForm
