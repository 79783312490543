import React from 'react'
import ItemsList from '../itemsList'
import { Descriptions, Typography } from 'antd'
import InfoItem from '../infoItem/InfoItem'
import SoftwareInPackageInfo from '../softwareInPackageInfo/softwareInPackageInfo'

const PackageInUnitInfo = ({ info, index }) => {
  const { Title } = Typography

  return (
    <Descriptions column={1}>
      <Descriptions.Item label='Название'>{info?.name}</Descriptions.Item>
      <Descriptions.Item label='Описание'>{info?.description}</Descriptions.Item>

      <Descriptions.Item label='Внутренний id'>{info?.inner_id}</Descriptions.Item>

      <Descriptions.Item label='Заморожено'>
        {info?.freezed ? 'Да' : 'Нет'}
      </Descriptions.Item>

      <Descriptions.Item label='Дата создания'>{info?.created_at}</Descriptions.Item>
      <Descriptions.Item label='Дата обновления'>{info?.updated_at}</Descriptions.Item>
    </Descriptions>
  )
}

export default PackageInUnitInfo
