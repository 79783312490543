import React from 'react'
import { AppStore } from '../../store/store'
import { useQuery } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import TableComponent from '../../components/tableComponent/tableComponent'
import { commandColumns } from '../../columns/commands'
import CommandInfo from '../../components/commandInfo'
import { Divider } from 'antd'
import routesList from '../../server/routesList.json'

const Commands = () => {
  const offset = AppStore.useState((s) => s.table.offset)

  const limit = AppStore.useState((s) => s.table.limit)

  const { data: commandData, isLoading } = useQuery(['command_list', offset, limit], () =>
    apiRequest(routesList['pca/command']).get(offset, limit),
  )

  const modifiedCommandList = commandData?.items?.map((el) => ({ ...el, key: el.id }))

  return (
    <>
      <TableComponent
        title='Команды'
        total={commandData?.total}
        data={modifiedCommandList}
        columns={commandColumns}
        isLoading={isLoading}
        tabContent={(id, rowData) => {
          return <CommandInfo info={rowData} />
        }}
        limit={limit}
        isAddButtonHidden
      />

      <Divider orientation='left'>Команды</Divider>
      <p>Список команд отправленных на агентов</p>
    </>
  )
}

export default Commands
