import { convertDateWithMoment } from '../utils/hooks'

export const agentColumns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    render: (name) => {
      if (!!name) {
        return name
      }
      if (!name) {
        return 'Название отсутствует'
      }
    },
  },
  {
    title: 'Система',
    dataIndex: 'system',
    key: 'system',
  },
  {
    title: 'Пакетный менеджер',
    dataIndex: 'pkg_system',
    key: 'pkg_system',
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) => {
      return convertDateWithMoment(created_at)
    },
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (updated_at) => {
      return convertDateWithMoment(updated_at)
    },
  },
]
