import { AppStore } from '../store/store'

export const getUserName = () => {
  try {
    const userName = localStorage.getItem('username')
    return userName
  } catch (e) {
    AppStore.update((s) => {
      s.notifications.title = 'Ошибка'
      s.notifications.text = 'Ошибка при получении имени пользователя'
      s.notifications.type = 'error'
    })
    return 'username'
  }
}
