import React from 'react'
import { NavLink } from 'react-router-dom'
import s from './notFoundPage.module.css'

const NotFoundPage = () => {
  return (
    <div className={s.notFound}>
      <h2 className={s.notFound__text}>Страница не найдена</h2>
      <NavLink className={s.notFound__link} to='/'>
        Вернуться на главную страницу
      </NavLink>
    </div>
  )
}

export default NotFoundPage
