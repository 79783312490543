import React from 'react'
import s from '../../pages/units/units.module.css'
import { DesktopOutlined } from '@ant-design/icons'
import { Card, Descriptions, List, Typography } from 'antd'
import { convertDateWithMoment } from '../../utils/hooks'

const SoftOnUnitsPage = ({ softFormPackages, setId, setIsVisible, soft_id }) => {
  const { Title } = Typography

  const softItems = softFormPackages?.map((el) => {
    return (
      <List.Item key={el.id} style={{ height: '100%' }}>
        <Card
          className={s.cardInfo}
          onClick={() => {
            setId((oldValue) => ({ ...oldValue, soft_id: el.id }))
            setIsVisible((oldValue) => ({ ...oldValue, softItem: true }))
          }}
          style={{ backgroundColor: soft_id === el.id ? '#c1c5cb' : '' }}
        >
          <Descriptions column={1}>
            <Descriptions.Item label='Название'>{el?.software?.name}</Descriptions.Item>

            <Descriptions.Item label='Описание'>
              {el?.software?.description}
            </Descriptions.Item>

            <Descriptions.Item label='Версия софта'>
              {el?.software?.version}
            </Descriptions.Item>

            <Descriptions.Item label='Вид софта'>{el?.software?.kind}</Descriptions.Item>

            <Descriptions.Item label='GitLab ID'>
              {el?.software?.gitlab_id}
            </Descriptions.Item>

            <Descriptions.Item label='Ссылка на репозиторий'>
              <a href={el?.software?.url}>{el?.software?.url}</a>
            </Descriptions.Item>

            <Descriptions.Item label='Дата создания'>
              {convertDateWithMoment(el?.software?.created_at)}
            </Descriptions.Item>

            <Descriptions.Item label='Дата изменения'>
              {convertDateWithMoment(el?.software?.updated_at)}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </List.Item>
    )
  })

  return (
    <>
      {softFormPackages.length > 0 ? (
        <div className={s.box}>
          <Title style={{ color: '#505962' }} level={2}>
            <DesktopOutlined /> Софт
          </Title>
          <List
            grid={{ gutter: [4, 16], xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            dataSource={softItems}
            renderItem={(item) => item}
          />
        </div>
      ) : null}
    </>
  )
}

export default SoftOnUnitsPage
