export const getUnitStatus = (status) => {
  switch (status) {
    case 'created':
      return 'Создан'
    case 'test':
      return 'Тест'
    case 'approved':
      return 'Тест пройден'
    case 'rejected':
      return 'Тест отклонен'
    default:
      return 'Статус не указан'
  }
}
