export const softKindListOptions = [
  {
    value: 'driver',
    label: 'Драйвер',
  },
  {
    value: 'server',
    label: 'Сервер',
  },
  {
    value: 'front',
    label: 'Фронт',
  },
  {
    value: 'application',
    label: 'Приложение',
  },
  {
    value: 'terminal',
    label: 'Терминал',
  },
]
