export const filterUnits = (units) => {
  const example_unit_list = [...units]

  const chains = example_unit_list
    .filter((unit) => !unit.prev_unit_id)
    .map((unit) => [unit])

  example_unit_list.sort((a, b) => a.id - b.id)

  for (const unit of example_unit_list) {
    if (!unit.prev_unit_id) {
      // eslint-disable-next-line no-continue
      continue
    }
    for (const chain of chains) {
      if (unit.prev_unit_id === chain[chain.length - 1].id) {
        chain.push(unit)
      }
    }
  }

  return chains
}
