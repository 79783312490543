import React, { useState } from 'react'
import s from './tableDrawerContent.module.css'
import { Tabs } from 'antd'
import { AppStore } from '../../store/store'

const TableDraweContent = ({
  info,
  edit,
  legacyMode,
  isEditing,
  tabs,
  test,
  command,
}) => {
  const [tableDrawerState, setTableDrawerState] = useState('info')

  const onChangeHandle = (state) => {
    setTableDrawerState(state)

    if (state === 'legacy') {
      AppStore.update((s) => {
        s.units.legacyMode = true
      })

      return
    }

    if (state === 'edit') {
      AppStore.update((s) => {
        s.units.legacyMode = false
      })

      return
    }
  }

  return (
    <div className={s.tableDrawerContent}>
      <div className={s.wrapper}>
        <Tabs card='card' tabPosition='left' items={tabs} onChange={onChangeHandle} />

        <div className={s.content}>
          {tableDrawerState === 'info' && info()}
          {tableDrawerState === 'edit' && edit()}
          {tableDrawerState === 'legacy' && !!legacyMode && edit()}
          {tableDrawerState === 'test' && test()}
          {tableDrawerState === 'command' && command()}
        </div>
      </div>
    </div>
  )
}

export default TableDraweContent
