import React, { useEffect } from 'react'
import s from './agentForm.module.css'
import { Button, Checkbox, Form, Input, Select } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { AppStore } from '../../store/store'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const AgentForm = ({ id, info, setIsDrawerVisible }) => {
  const [form] = Form.useForm()

  const handleChange = (value) => {
    console.log(`selected ${value}`)
  }

  const queryClient = useQueryClient()

  const { mutate: editAgent } = useMutation(['edit_agent'], {
    mutationFn: ({ id, payload }) => apiRequest(routesList.agent).update({ id, payload }),
    onSuccess: () => {
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Информация об агенте обновлена'
        s.notifications.type = 'success'
      })
      queryClient.invalidateQueries('agent_list')
      setIsDrawerVisible(false)
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при обновлении информации об агенте'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const onSubmit = (payload) => {
    editAgent({ id, payload })
  }

  useEffect(() => {
    form.setFieldValue('name', info.name)
    form.setFieldValue('description', info.description)
    form.setFieldValue('dev', info.dev)
  }, [info])

  return (
    <Form
      name='soft'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      <Form.Item label='Название' name='name'>
        <Input />
      </Form.Item>

      <Form.Item label='Описание' name='description'>
        <Input />
      </Form.Item>

      <Form.Item label='Развернуто' name='dev'>
        <Select
          onChange={handleChange}
          options={[
            { value: true, label: 'У нас' },
            { value: false, label: 'У клиента' },
          ]}
        />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Отправить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AgentForm
