import React from 'react'
import InfoItem from '../infoItem/InfoItem'
import { Descriptions, Typography } from 'antd'
import SoftInfo from '../softInfo/softInfo'
import PackageInUnitInfo from '../packageInUnitInfo'

const PackageItemsInfo = ({ info, index }) => {
  const { Title } = Typography

  return (
    <>
      <Descriptions column={1}>
        <Descriptions.Item label='auto_patch_times'>
          {JSON.stringify(info?.auto_patch_times)}
        </Descriptions.Item>

        <Descriptions.Item label='Порядок установки'>
          {info?.install_order}
        </Descriptions.Item>

        <Descriptions.Item label='Патч ID'>
          {JSON.stringify(info?.patch_id)}
        </Descriptions.Item>
      </Descriptions>

      <SoftInfo info={info.software} />
    </>
  )
}

export default PackageItemsInfo
