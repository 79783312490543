import React from 'react'
import { getToken } from '../../utils/authorization'
import { Navigate } from 'react-router'

const PrivateRoute = ({ children }) => {
  if (getToken()) {
    return <>{children}</>
  }
  return <Navigate to='/login' />
}

export default PrivateRoute
