import { convertDateWithMoment } from '../utils/hooks'

export const deployJobColumns = [
  {
    title: 'Прочитано',
    dataIndex: 'readed',
    key: 'readed',
    render: (readed) => (readed ? 'Да' : 'Нет'),
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) => {
      return convertDateWithMoment(created_at)
    },
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (updated_at) => {
      return convertDateWithMoment(updated_at)
    },
  },
]
