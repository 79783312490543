import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, Row, Table, Typography } from 'antd'
import TableDraweContent from '../tableDrawerContent/TableDraweContent'
import { AppStore } from '../../store/store'
import { UserAddOutlined } from '@ant-design/icons'

const TableComponent = ({
  isLoading,
  data,
  columns,
  form,
  title,
  tabContent,
  edit,
  legacy,
  isEditing,
  isAddButtonHidden,
  tabs,
  test,
  command,
  limit,
  total,
  setIsDrawerVisible,
  isDrawerVisible,
}) => {
  const { Title } = Typography

  const [rowID, setRowID] = useState(null)

  const [dataRow, setDataRow] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)

  const onAddClickHandle = () => {
    setIsDrawerVisible(true)
    setRowID(null)
  }

  return (
    <>
      <Drawer
        title={title ? title : ''}
        width='1000px'
        placement='right'
        closable={true}
        onClose={() => {
          setIsDrawerVisible(false)
          AppStore.update((s) => {
            s.units.legacyMode = false
          })
        }}
        open={isDrawerVisible}
        getContainer={false}
        style={{ backgroundColor: 'lightgray' }}
      >
        {rowID ? (
          <TableDraweContent
            info={() => tabContent(rowID, dataRow)}
            edit={() => edit(rowID, dataRow)}
            test={() => test()}
            command={() => command(rowID, dataRow)}
            legacyMode={legacy}
            isEditing={isEditing}
            tabs={tabs}
          />
        ) : (
          form
        )}
      </Drawer>

      <Row>
        <Col span={24}>
          <Title level={2} style={{ color: '#505962' }}>
            {title}
          </Title>
        </Col>
      </Row>

      {!isAddButtonHidden && (
        <Row style={{ marginBottom: 15 }}>
          <Col span={24}>
            <Button type='primary' onClick={onAddClickHandle} size='large'>
              <UserAddOutlined />
              Добавить
            </Button>
          </Col>
        </Row>
      )}

      <Row>
        <Col span={24}>
          <Table
            showSizeChanger={false}
            loading={isLoading}
            dataSource={data}
            pagination={{
              total,
              defaultPageSize: limit,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page)
                AppStore.update((s) => {
                  s.table.offset = (page - 1) * limit
                })
              },
            }}
            columns={columns}
            onRow={(rowData) => {
              return {
                onClick: (e) => {
                  e.stopPropagation()
                  setIsDrawerVisible(true)
                  setRowID(rowData.id)
                  setDataRow(rowData)
                },
              }
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default TableComponent
