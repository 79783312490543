export const agentTabs = [
  {
    key: 'info',
    label: `Информация`,
  },
  {
    key: 'command',
    label: `Команды`,
  },
]
