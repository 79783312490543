import React from 'react'
import ItemsList from '../itemsList'
import InfoItem from '../infoItem/InfoItem'
import PackageInUnitInfo from '../packageInUnitInfo'
import { Descriptions } from 'antd'

const UnitInfo = ({ id, info }) => {
  return (
    <Descriptions column={1}>
      <Descriptions.Item label='ID юнита'>{info?.id}</Descriptions.Item>

      <Descriptions.Item label='Статус'>{info?.status}</Descriptions.Item>

      {info?.packages?.map((el, index) => (
        <PackageInUnitInfo key={el.id} info={el} index={index + 1} />
      ))}
    </Descriptions>
  )
}

export default UnitInfo
