import React, { useState } from 'react'
import { Pagination } from 'antd'
import { AppStore } from '../../store/store'

const CustomPagination = ({ total, limit }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const paginationChange = (page) => {
    setCurrentPage(page)
    AppStore.update((s) => {
      s.pagination.offset = (page - 1) * limit
    })
  }

  return (
    <Pagination
      current={currentPage}
      total={total}
      pageSize={limit}
      onChange={paginationChange}
      showSizeChanger={false}
    />
  )
}

export default CustomPagination
