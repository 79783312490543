import React from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient } from '../../server/ApiClient'

const ProjectForm = ({ id }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const { mutate: addProject } = useMutation(['add_project'], apiClient.addProject, {
    onSuccess: () => {
      queryClient.invalidateQueries('project_list')
      form.resetFields()
    },
  })

  const { mutate: updateProject } = useMutation(
    ['update_project_info'],
    apiClient.updateProjectItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('project_list')
      },
    },
  )

  useQuery(['get_pr_item_info', id], () => apiClient.getProjectItem(id), {
    enabled: !!id,
    onSuccess: (data) => {
      form.setFieldsValue(data)
    },
  })

  const onSubmit = (payload) => {
    if (!id) {
      addProject(payload)
      return
    }

    if (id) {
      updateProject({ id, payload })
      return
    }
  }

  return (
    <Form
      name='add_project'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      <Form.Item
        label='Название компании'
        name='name'
        rules={[
          {
            required: true,
            message: 'Введите название компании',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Описание'
        name='description'
        rules={[
          {
            required: true,
            message: 'Введите описание компании',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ProjectForm
