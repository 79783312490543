import React from 'react'
import InfoItem from '../infoItem/InfoItem'
import { useQuery } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'

const ProjectInfo = ({ id, info }) => {
  const { data: projectInfo } = useQuery(
    ['get_project_item_page_info', id],
    () => apiRequest(routesList.product).getItem(id),
    {
      enabled: !!id,
    },
  )

  const projectItemInfo = id ? projectInfo : info
  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15 }}>
      <InfoItem title='Название' name={projectItemInfo?.name} />
      <InfoItem title='Описание' name={projectItemInfo?.description} />
    </div>
  )
}

export default ProjectInfo
