import React from 'react'
import { useQuery } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { v4 as uuidv4 } from 'uuid'
import { Collapse, Descriptions } from 'antd'
import PackageItemsInfo from '../PackageItemsInfo/PackageItemsInfo'
import { convertDateWithMoment } from '../../utils/hooks'
import routesList from '../../server/routesList.json'

const PackageHistoryInfo = ({ packageInfo, packageInfoId }) => {
  const { data: packageInfoData } = useQuery(
    ['package_info_item', packageInfoId],
    () => apiRequest(routesList.package).getItem(packageInfoId),
    {
      enabled: !!packageInfoId,
    },
  )

  const packageData = packageInfoData ? packageInfoData : packageInfo

  const { Panel } = Collapse

  return (
    <>
      <Descriptions column={1}>
        <Descriptions.Item label='Название'>{packageData?.name}</Descriptions.Item>
        <Descriptions.Item label='Описание'>{packageData?.description}</Descriptions.Item>

        <Descriptions.Item label='Внутренний id'>
          {packageData?.inner_id}
        </Descriptions.Item>

        <Descriptions.Item label='Заморожено'>
          {packageData?.freezed ? 'Да' : 'Нет'}
        </Descriptions.Item>

        <Descriptions.Item label='Дата создания'>
          {convertDateWithMoment(packageData?.created_at)}
        </Descriptions.Item>

        <Descriptions.Item label='Дата изменения'>
          {convertDateWithMoment(packageData?.updated_at)}
        </Descriptions.Item>
      </Descriptions>

      <Collapse>
        {packageData?.packageitems?.map((el, index) => {
          return (
            <Panel key={el.id} header={`Элемент входящий в пакет ${index + 1}`}>
              <PackageItemsInfo key={uuidv4()} info={el} index={index} />
            </Panel>
          )
        })}
      </Collapse>
    </>
  )
}

export default PackageHistoryInfo
