import React from 'react'
import { Button } from 'antd'

const DeletePopoverContent = ({ deleteItem, text }) => {
  return (
    <Button
      type='primary'
      onClick={(e) => {
        e.stopPropagation()
        deleteItem()
      }}
      danger
    >
      {text ? text : 'Удалить'}
    </Button>
  )
}

export default DeletePopoverContent
