import React, { useEffect } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { AppStore } from '../../store/store'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const UnitForm = ({ unitData, id, closeDrawer }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const legacyMode = AppStore.useState((s) => s.units.legacyMode)

  const { mutate: addUnit } = useMutation(['add_unit'], {
    mutationFn: (payload) => apiRequest(routesList.unit).add(payload),
    onSuccess: () => {
      form.resetFields()
      queryClient.invalidateQueries('units_list')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Юнит создан'
        s.notifications.type = 'success'
      })
      closeDrawer()
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Не удалось создать юнит'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const { mutate: inheritUnit } = useMutation(['inherit_unit'], {
    mutationFn: (payload) => apiRequest(`${routesList.unit}/${id}/inherit`).add(payload),
    onSuccess: () => {
      queryClient.invalidateQueries('units_list')
    },
  })

  const { data: productsList } = useQuery(['get_pr_list_select_units'], () =>
    apiRequest(routesList.product).get(),
  )

  const { data: packagesList } = useQuery(['get_packages_list_select_units'], () =>
    apiRequest(routesList.package).get(),
  )

  const modifiedProductsList = productsList?.items?.map((el) => ({
    value: el?.id,
    label: el?.name,
  }))

  const modifiedPackagesListList = packagesList?.items
    ?.filter((item) => !item.freezed && !item.outdated && !item.unit_id)
    ?.map((el) => ({
      value: el?.id,
      label: el?.name,
    }))

  const onSubmit = (payload) => {
    if (!legacyMode && !id) {
      addUnit(payload)

      return
    }

    if (!!legacyMode && !!id) {
      inheritUnit({ id, payload })
      return
    }
  }

  return (
    <Form
      name='add_unit'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      {!legacyMode && !id && (
        <Form.Item label='Продукт' name='product_id'>
          <Select
            placeholder='Выберите продукт'
            allowClear
            options={modifiedProductsList}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      )}

      <Form.Item label='Пакеты' name='packages_id'>
        <Select
          placeholder='Выберите пакеты'
          allowClear
          options={modifiedPackagesListList}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          mode='multiple'
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UnitForm
