import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useQuery } from 'react-query'
import TableComponent from '../../components/tableComponent/tableComponent'
import { apiClient, apiRequest } from '../../server/ApiClient'
import s from './packageHistory.module.css'
import { packagesColumns } from '../../columns/packages'
import PackageHistoryCardInfo from '../../components/PackageHistoryCardInfo/PackageHistoryCardInfo'
import { packageHistoryTabs } from '../../constans/tabs/packageHistory'
import { AppStore } from '../../store/store'
import { Divider } from 'antd'
import routesList from '../../server/routesList.json'

const PackageHistory = () => {
  const offset = AppStore.useState((s) => s.table.offset)

  const limit = AppStore.useState((s) => s.table.limit)

  const { data: packagesList, isLoading } = useQuery(['packages_list'], () =>
    apiRequest(`${routesList.package}/history`).get(offset, limit, { order_by: '-id' }),
  )

  const newArr = []

  packagesList?.items
    ?.map((el) => {
      return { el: el?.products, client_name: el.name }
    })
    .map((el) => {
      return el?.el?.map((el1) => {
        return { client_name: el.client_name, ...el1, product_name: el1.name }
      })
    })
    ?.flat()
    ?.map((el) => {
      const package_by_name = el.packages_by_name

      for (const [key, value] of Object.entries(package_by_name)) {
        console.log(value)
        newArr.unshift({
          packagesHistory: value,
          package_name: `${value[0].name} ${
            value[0].inner_id ? ` || id: ${value[0].inner_id}` : ''
          }`,
          ...value[0],
          client_name: el.client_name,
          product_name: el.product_name,
        })
      }

      return el
    })

  const modifiedInsideArrays = newArr?.map((el) => {
    return { key: uuidv4(), ...el }
  })

  console.log(modifiedInsideArrays)

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  return (
    <div className={s.packageHistory}>
      <TableComponent
        title='История пакетов'
        total={packagesList?.total}
        limit={limit}
        data={modifiedInsideArrays}
        columns={packagesColumns}
        isLoading={isLoading}
        tabContent={(id, rowData) => {
          return <PackageHistoryCardInfo info={rowData} />
        }}
        isAddButtonHidden
        tabs={packageHistoryTabs}
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
      />

      <div className='dividerBox'>
        <Divider orientation='left'>Описание</Divider>
        <p>
          В истории пакетов отображаются привязанные к Юнитам пакеты и история их
          изменения
        </p>
      </div>
    </div>
  )
}

export default PackageHistory
