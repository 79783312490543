import React from 'react'
import { Timeline } from 'antd'
import { convertDateWithMoment } from '../../utils/hooks'
import { ClockCircleOutlined } from '@ant-design/icons'

const PackageHistoryCardInfo = ({ info }) => {
  const timelineInfo = info?.packagesHistory?.map((el) => {
    return {
      label: (
        <div>
          <p>{convertDateWithMoment(el?.created_at)}</p>
          <p>
            {el.description}|| id:{el.inner_id}
          </p>
        </div>
      ),
      children: el?.packageitems?.map((el1, index) => {
        return (
          <p key={el1.id}>
            {el1.software.name}-{el1.software.branch}-{el1.software.version}
            {el1.software.patch ? el1.software.patch : ''}
          </p>
        )
      }),
    }
  })

  return <Timeline items={timelineInfo} mode='left' />
}

export default PackageHistoryCardInfo
