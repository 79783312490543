import React, { useState } from 'react'
import TableComponent from '../../components/tableComponent/tableComponent'
import { AppStore } from '../../store/store'
import { useQuery, useQueryClient } from 'react-query'
import s from './agent.module.css'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { agentColumns } from '../../columns/agent'
import AgentInfo from '../../components/agentInfo'
import { agentTabs } from '../../constans/tabs/agent'
import AddCommandForm from '../../components/addCommandForm'
import { Button, Col, Divider, Row } from 'antd'
import AgentForm from '../../components/agentForm'
import { DownloadOutlined } from '@ant-design/icons'
import routesList from '../../server/routesList.json'

const Agent = () => {
  const queryClient = useQueryClient()

  const offset = AppStore.useState((s) => s.table.offset)

  const limit = AppStore.useState((s) => s.table.limit)

  const { data: agentData, isLoading } = useQuery(['agent_list', offset, limit], () =>
    apiRequest(routesList.agent).get(offset, limit, { order_by: '-id' }),
  )

  const modifiedAgentList = agentData?.items?.map((el) => ({ ...el, key: el.id }))

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const [isFileLoading, setIsFileLoading] = useState(false)

  const [lastAgentStatus, setLastAgentStatus] = useState(false)

  const [currentBuildID, setCurrentBuildID] = useState(null)

  const downloadEmployeeData = () => {
    setIsFileLoading(true)

    setLastAgentStatus(true)

    return apiClient
      .downloadBuild(currentBuildID)
      .then((res) => {
        const link = document.createElement('a')

        const arr = []

        arr.push(res)

        const url = window.URL.createObjectURL(
          new Blob(arr, { type: 'application/zip ' }),
        )

        link.href = url

        link.click()

        AppStore.update((s) => {
          s.notifications.title = 'Успешно'
          s.notifications.text = 'Билд успешно загружен'
          s.notifications.type = 'success'
        })
      })
      .catch((e) => {
        AppStore.update((s) => {
          s.notifications.title = 'Ошибка'
          s.notifications.text = 'Не удалось скачать билд'
          s.notifications.type = 'error'
        })
      })
      .finally((res) => {
        setIsFileLoading(false)
      })
  }

  useQuery(['latest_agent'], () => apiRequest(`${routesList.agent}/latest`).get(), {
    onSuccess: (data) => {
      setCurrentBuildID(data.build.id)
    },
  })

  return (
    <div className={s.agent}>
      <Row style={{ marginBottom: 10 }}>
        <Col xxl={{ offset: 19, span: 4 }}>
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            size='large'
            onClick={downloadEmployeeData}
            loading={isFileLoading}
          >
            Загрузить последний билд агента
          </Button>
        </Col>
      </Row>

      <TableComponent
        title='Агенты'
        data={modifiedAgentList}
        total={agentData?.total}
        columns={agentColumns}
        isLoading={isLoading}
        tabContent={(id, rowData) => {
          return <AgentInfo info={rowData} id={id} />
        }}
        isAddButtonHidden
        edit={(id, rowData) => (
          <AgentForm id={id} info={rowData} setIsDrawerVisible={setIsDrawerVisible} />
        )}
        tabs={agentTabs}
        command={(id, rowData) => {
          return <AddCommandForm agent_id={id} info={rowData} />
        }}
        limit={limit}
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
      />

      <div className='dividerBox'>
        <Divider orientation='left'>Описание</Divider>

        <p>Машины с указанием ПО установленного на них</p>
      </div>
    </div>
  )
}

export default Agent
