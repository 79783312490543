import React, { useState } from 'react'
import s from './login.module.css'
import { Button, Form, Input, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import logo from '../../assets/images/logo.png'
import { routesPath } from '../../routing/path'
import { apiClient } from '../../server/ApiClient'
import { AppStore } from '../../store/store'
import { LoginOutlined } from '@ant-design/icons'

const Login = () => {
  const [form] = Form.useForm()

  const history = useNavigate()

  const [authError, setAuthError] = useState('')

  const { mutate: login, isLoading } = useMutation(['login'], apiClient.login, {
    onSuccess: (data) => {
      history(routesPath.clients)
      if (data) {
        AppStore.update((s) => {
          s.notifications.title = 'Успешно'
          s.notifications.text = `Добро пожаловать ${data.user.login}`
          s.notifications.type = 'success'
        })

        localStorage.setItem(
          'username',
          `${data?.user?.surname ? data?.user?.surname : ''} ${
            data?.user?.name ? data?.user?.name : ''
          } ${data?.user?.patronymic ? data?.user?.patronymic : ''} ${data?.user?.login}`,
        )
      }
    },
    onError: (error) => {
      if (error.response.data.status === 401) {
        setAuthError('Неправильный логин или пароль')
      }
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Не удается войти'
        s.notifications.type = 'error'
      })
    },
  })

  const onSubmit = (payload) => {
    login(payload)
  }

  const { Title } = Typography

  return (
    <div className={s.login}>
      <div className={s.wrapper}>
        <img className={s.img} src={logo} alt='логотип' />
        <span className={s.version}>v {process.env.REACT_APP_VERSION}</span>

        <Title level={3} style={{ textAlign: 'center' }}>
          Релизный портал
        </Title>

        <Form
          name='add_project'
          layout='vertical'
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          autoComplete='off'
          onFinish={onSubmit}
          style={{ width: '100%' }}
        >
          <Form.Item
            label='Логин'
            name='login'
            rules={[
              {
                required: true,
                message: 'Введите логин',
              },
            ]}
          >
            <Input placeholder='Введите логин' size='large' />
          </Form.Item>

          <Form.Item
            label='Пароль'
            name='password'
            rules={[
              {
                required: true,
                message: 'Введите пароль',
              },
            ]}
          >
            <Input.Password placeholder='Введите пароль' size='large' />
          </Form.Item>

          <span className={s.errorText}>{authError}</span>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 24,
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              style={{ width: '100%' }}
              loading={isLoading}
            >
              <LoginOutlined />
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login
