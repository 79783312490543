import React from 'react'
import s from './infoitem.module.css'

const InfoItem = ({ title, name, isLink }) => {
  return (
    <div className={s.infoItem} style={{ display: 'flex', columnGap: '10px' }}>
      <h3 className={s.title}>{title}:</h3>
      {isLink ? (
        <a href={name} target='_blank' rel='noreferrer'>
          {name}
        </a>
      ) : (
        <span className={s.text}>{name ? name : 'Информация отсутствует'}</span>
      )}
    </div>
  )
}

export default InfoItem
