import { convertDateWithMoment } from '../utils/hooks'

export const packagesColumns = [
  {
    title: 'Клиент',
    dataIndex: 'client_name',
    key: 'client',
  },
  {
    title: 'Название продукта',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: 'Название пакета',
    dataIndex: 'package_name',
    key: 'package_name',
  },
  {
    title: 'Последнее обновление',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (updated_at) => {
      return convertDateWithMoment(updated_at)
    },
  },
]
