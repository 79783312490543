import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import {
  Button,
  Card,
  Cascader,
  Col,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Row,
  Skeleton,
  Typography,
  Badge,
  Descriptions,
} from 'antd'
import s from './soft.module.css'
import { convertDateWithMoment, useDebounce } from '../../utils/hooks'
import SoftForm from '../../components/softForm/softForm'
import { DownloadOutlined, PlusSquareOutlined } from '@ant-design/icons'
import routesList from '../../server/routesList.json'

const Index = () => {
  const { Panel } = Collapse

  const queryClient = useQueryClient()
  const { Title } = Typography

  const [searchValue, setSearchValue] = useState('')

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const closeDrawer = () => {
    setIsDrawerVisible(false)
  }

  const [isPatchesVisible, setIsPatchesVisible] = useState(false)

  const [gitLabId, setGitLabId] = useState(null)

  const [options, setOptions] = useState([])

  const [branchName, setBranchName] = useState(null)

  const [version, setVersion] = useState(null)

  const debounceSearch = useDebounce(searchValue, 1000)

  const [isCascaderOpen, setIsCascaderOpen] = useState(false)

  const {
    isLoading: isSoftwareLoading,
    isSuccess: isSoftwareSoftSuccess,
    data: softwareList,
  } = useQuery(
    ['soft_list', debounceSearch],
    () =>
      apiRequest(routesList.software).get(0, 200, {
        name__icontains: debounceSearch,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          setOptions(
            data?.items?.map((soft) => {
              return {
                label: soft.name,
                value: soft.gitlab_id,
                isLeaf: false,
              }
            }),
          )
        }
        setIsCascaderOpen(true)
      },
    },
  )

  useQuery(
    ['get_branches_list', gitLabId],
    () => apiRequest(`${routesList.software}/${gitLabId}/branches`).get(),
    {
      enabled: !!gitLabId,
      onSuccess: (data) => {
        if (data) {
          setOptions((oldValue) =>
            oldValue.map((item) => {
              return {
                ...item,
                children: data?.items.map((item1) => {
                  return {
                    label: item1.branch,
                    value: item1.branch,
                    children: [
                      {
                        value: 'Нет данных',
                        label: 'Нет данных',
                      },
                    ],
                  }
                }),
              }
            }),
          )
        }
      },
    },
  )

  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

  useQuery(
    ['get_versions_list', gitLabId, branchName],
    () => apiRequest(`/software/${gitLabId}/branches/${branchName}/versions`).get(),
    {
      enabled: !!gitLabId && !!branchName,
      onSuccess: (data) => {
        setOptions((oldValue) =>
          oldValue?.map((el) => {
            return {
              ...el,
              children: el.children?.map((el1) => {
                return {
                  ...el1,
                  children: data?.items
                    ?.map((el2) => {
                      setVersion(el2)
                      return {
                        label: el2.version,
                        value: JSON.stringify(el2),
                        isLeaf: true,
                      }
                    })
                    .sort((a, b) => collator.compare(b.label, a.label)),
                }
              }),
            }
          }),
        )
      },
    },
  )

  const onChange = (valueArr, value) => {
    if (valueArr.length === 1) {
      setGitLabId(valueArr[0])
      setBranchName(null)
      setIsPatchesVisible(false)
      return
    }

    if (valueArr.length >= 2) {
      setBranchName(valueArr[1])
    }

    if (valueArr.length === 3) {
      setVersion(JSON.parse(valueArr[2]))
      setIsPatchesVisible(true)
    }
  }

  const [isDownloading, setIsDownloading] = useState(false)

  const [currentBuildID, setCurrentBuildID] = useState(null)

  const downloadEmployeeData = (id) => {
    setIsDownloading(true)

    return apiClient
      .downloadBuild(id)
      .then((res) => {
        const link = document.createElement('a')

        const arr = []

        arr.push(res)

        const url = window.URL.createObjectURL(
          new Blob(arr, { type: 'application/zip ' }),
        )
        link.href = url
        link.click()
      })
      .finally((res) => {
        setIsDownloading(false)
        setCurrentBuildID(null)
      })
  }

  const [software_id, set_software_id] = useState(null)

  const [formState, setFormState] = useState('')

  const getDrawerContentState = (state) => {
    switch (state) {
      case 'add':
        return <SoftForm closeDrawer={closeDrawer} />
      case 'edit':
        return <SoftForm closeDrawer={closeDrawer} id={software_id} />
      default:
        return 'Нет контента'
    }
  }

  const versionsData = (
    <div>
      <Title level={3}>Версия {version?.version}</Title>

      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
        <Collapse>
          {version?.patches
            ?.map((el) => {
              return (
                <Panel key={el.id} header={`Патч № ${el.patch}`}>
                  <div style={{ display: 'flex', columnGap: 15 }}>
                    <Title level={3}>Патч № {el.patch}</Title>

                    <Button
                      type='primary'
                      size='large'
                      style={{
                        maxWidth: '160px',
                        width: '100%',
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        set_software_id(
                          version?.patches[version.patches.length - 1]?.builds?.[0]
                            ?.software_id,
                        )
                        setFormState('edit')
                        setIsDrawerVisible(true)
                      }}
                    >
                      <PlusSquareOutlined />
                      Редактировать
                    </Button>
                  </div>

                  <Row gutter={[16, 16]}>
                    {el?.builds?.map((el1, index1) => {
                      return (
                        <Col xxl={5} xl={8} lg={12} md={12} key={el1.id}>
                          <Badge.Ribbon text={el1.target_os}>
                            <Card>
                              <Title level={2}>Билд № {index1 + 1}</Title>

                              <Descriptions column={1}>
                                <Descriptions.Item label='Название'>
                                  {el1.name}
                                </Descriptions.Item>

                                <Descriptions.Item label='Тип пакеты'>
                                  {el1.package_type}
                                </Descriptions.Item>

                                <Descriptions.Item label='Дата создания'>
                                  {convertDateWithMoment(el1.created_at)}
                                </Descriptions.Item>

                                <Descriptions.Item label='Дата обновления'>
                                  {convertDateWithMoment(el1.updated_at)}
                                </Descriptions.Item>
                              </Descriptions>

                              <Button
                                size='large'
                                type='primary'
                                onClick={() => {
                                  setCurrentBuildID(el1.id)
                                  downloadEmployeeData(el1.id)
                                }}
                                loading={isDownloading && currentBuildID === el1.id}
                              >
                                <DownloadOutlined />
                                Скачать
                              </Button>
                            </Card>
                          </Badge.Ribbon>
                        </Col>
                      )
                    })}
                  </Row>
                </Panel>
              )
            })
            .sort((a, b) => collator.compare(b.patch, a.patch))
            .map((el, index, array) => {
              if (index + 1 === array.length) {
                return el
              }
              return ''
            })}
        </Collapse>
      </div>
    </div>
  )

  const onSearch = (value) => {
    setSearchValue(value)
  }

  return (
    <div className={s.soft}>
      <Title level={2} style={{ color: '#505962' }}>
        Софт
      </Title>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          justifyContent: 'flex-start',
        }}
      >
        <Row>
          <Col xxl={{ offset: 21 }} xl={{ offset: 20 }} lg={{ offset: 18 }}>
            <Button
              type='primary'
              size='large'
              style={{ maxWidth: '140px', width: '100%', marginBottom: 10 }}
              onClick={() => {
                setFormState('add')
                setIsDrawerVisible(true)
              }}
            >
              <PlusSquareOutlined />
              Добавить
            </Button>
          </Col>
        </Row>

        {isSoftwareLoading && <Skeleton />}
        {!isSoftwareSoftSuccess && !isSoftwareLoading && <Empty />}

        <Cascader
          options={options}
          placeholder='Софт'
          searchValue={searchValue}
          onChange={onChange}
          changeOnSelect
          style={{ maxWidth: '400px', width: '100%' }}
          showSearch
          onSearch={onSearch}
        />

        {!!isSoftwareSoftSuccess && !isSoftwareLoading && (
          <>{!!version && !!isPatchesVisible ? versionsData : null}</>
        )}
      </div>

      <Drawer
        title='Добавление софта'
        width='1000px'
        placement='right'
        closable={true}
        onClose={() => {
          closeDrawer()
          setFormState('')
        }}
        open={isDrawerVisible}
        getContainer={false}
        style={{ backgroundColor: 'lightgray' }}
      >
        {/*<SoftForm closeDrawer={closeDrawer} />*/}
        {getDrawerContentState(formState)}
      </Drawer>

      <div className='dividerBox'>
        <Divider orientation='left'>Описание</Divider>
        <p>Раздел для добавления программного обеспечения</p>
      </div>
    </div>
  )
}

export default Index
