import React, { useEffect, useState } from 'react'
import s from './wrapper.module.css'
import { removeToken } from '../../utils/authorization'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Layout, Menu, Button, Typography } from 'antd'
import logo from '../../assets/images/logo.png'
import { routesPath } from '../../routing/path'
import { AppStore } from '../../store/store'
import {
  CodeOutlined,
  ContainerOutlined,
  DesktopOutlined,
  IdcardOutlined,
  InboxOutlined,
  LineChartOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { getUserName } from '../../utils/userInfo'

const Wrapper = () => {
  const navigate = useNavigate()

  const { Header, Content, Sider } = Layout

  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
    }
  }

  const items = [
    getItem('Клиенты', routesPath.clients, <TeamOutlined />),
    getItem('Софт', routesPath.soft, <DesktopOutlined />),
    getItem('Продукты', routesPath.products, <InboxOutlined />),
    getItem('Сборки', routesPath.units, <CodeOutlined />),
    getItem('История пакетов', routesPath['packages-history'], <LineChartOutlined />),
    getItem('Агент', routesPath.agent, <IdcardOutlined />),
    getItem('Пользователи', routesPath.users, <UserOutlined />),
    getItem('Лицензии', routesPath.license, <ContainerOutlined />),
  ]

  const [collapsed, setCollapsed] = useState(false)

  const { pathname } = useLocation()

  const { Title } = Typography

  const handleLogout = () => {
    removeToken()
    navigate(routesPath.login)
    localStorage.removeItem('username')
  }

  return (
    <Layout
      hasSider
      style={{
        height: '100%',
      }}
    >
      <Sider
        style={{
          overflow: 'auto',
          // height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: '#3d3f41',
        }}
      >
        <div
          style={{
            padding: 25,
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(routesPath.clients)
          }}
        >
          <img
            src={logo}
            style={{
              height: '100px',
              width: '100px',
              objectFit: 'contain',
            }}
            alt='лого'
          />
        </div>

        <Menu
          onClick={({ key }) => {
            navigate(key)
          }}
          mode='inline'
          defaultSelectedKeys={[pathname === '/' ? routesPath.clients : pathname]}
          items={items}
          theme='dark'
          style={{ backgroundColor: '#3d3f41' }}
        />

        <div
          style={{
            display: 'flex',
            columnGap: 10,
            justifyContent: 'center',
            color: '#FFFFFF',
            paddingLeft: 10,
            position: 'absolute',
            bottom: 5,
            right: '50%',
            transform: 'translateX(50%)',
          }}
        >
          <span>v {process.env.REACT_APP_VERSION}</span>
        </div>
      </Sider>

      <Layout style={{ marginLeft: 200 }}>
        <Header
          style={{
            backgroundColor: '#3d3f41',
            color: '#FFFFFF',
            fontSize: '22px',
            paddingLeft: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Релизный портал
          <div className={s.userBox}>
            <span style={{ fontSize: 14 }}>{getUserName()}</span>

            <Button size='large' onClick={handleLogout} type='primary'>
              <LogoutOutlined />
              Выход
            </Button>
          </div>
        </Header>

        <Content
          style={{
            padding: '20px',
            overflow: 'initial',
            height: '100%',
            backgroundColor: 'lightgray',
          }}
        >
          {pathname === routesPath.agent ||
          pathname === routesPath.commands ||
          pathname === routesPath['deploy-job'] ? (
            <div className='agent_tabs'>
              {/*<Link*/}
              {/*  className={`agent_tab ${`${pathname}` === routesPath.agent && 'active'}`}*/}
              {/*  to={routesPath.agent}*/}
              {/*>*/}
              {/*  Агент*/}
              {/*</Link>*/}
              {/*<Link*/}
              {/*  className={`agent_tab ${*/}
              {/*    `${pathname}` === routesPath['deploy-job'] && 'active'*/}
              {/*  } `}*/}
              {/*  to={routesPath['deploy-job']}*/}
              {/*  style={{*/}
              {/*    pointerEvents: 'none',*/}
              {/*    backgroundColor: 'gray',*/}
              {/*    color: 'lightgray',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Задачи развертывания*/}
              {/*</Link>*/}
              {/*<Link*/}
              {/*  className={`agent_tab ${*/}
              {/*    `${pathname}` === routesPath.commands && 'active'*/}
              {/*  }`}*/}
              {/*  to={routesPath.commands}*/}
              {/*>*/}
              {/*  Команды*/}
              {/*</Link>*/}
            </div>
          ) : null}

          {pathname === routesPath.units || pathname === routesPath['free-packages'] ? (
            <div className='agent_tabs'>
              <Link
                className={`agent_tab ${`${pathname}` === routesPath.units && 'active'}`}
                to={routesPath.units}
              >
                Юниты
              </Link>
              <Link
                className={`agent_tab ${
                  `${pathname}` === routesPath['free-packages'] && 'active'
                } `}
                to={routesPath['free-packages']}
              >
                Неприсвоенные пакеты
              </Link>
            </div>
          ) : null}

          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Wrapper
