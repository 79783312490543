import { convertDateWithMoment } from '../utils/hooks'
import { Button, Popover } from 'antd'
import DeletePopoverContent from '../components/deletePopoverContent'
import { DeleteOutlined, KeyOutlined } from '@ant-design/icons'
import React from 'react'
import { useQuery } from 'react-query'
import { apiRequest } from '../server/ApiClient'
import routesList from '../server/routesList.json'

export const licenseColumns = (deleteUser, getKeyLicense) => {
  return [
    {
      title: 'Поля',
      dataIndex: 'fields',
      key: 'fields',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        return convertDateWithMoment(created_at)
      },
    },
    {
      title: 'Годен до',
      dataIndex: 'valid_to',
      key: 'valid_to',
      render: (valid_to) => {
        return convertDateWithMoment(valid_to)
      },
    },
    {
      title: 'Ключ',
      dataIndex: 'key',
      key: 'key',
      render: (_, data) => {
        return (
          <Popover
            content={
              <DeletePopoverContent
                text='Получить ключ'
                deleteItem={() => {
                  getKeyLicense(data.id)
                }}
              />
            }
          >
            <Button
              type='link'
              onClick={(e) => {
                e.stopPropagation()
              }}
              icon={<KeyOutlined />}
            />
          </Popover>
        )
      },
    },
  ]
}
