import s from './units.module.css'
import React, { useState } from 'react'
import { Button, Col, Divider, Drawer, Row } from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'
import UnitForm from '../../components/unitForm/UnitForm'
import ClientsOnUnitsPage from '../../components/clientsOnUnitsPage'
import ProductsOnUnitsPage from '../../components/productsOnUnitsPage'
import UnitsOnUnitsPage from '../../components/unitsOnUnitsPage'
import PackagesOnUnitsPage from '../../components/packagesOnUnitsPage'
import SoftOnUnitsPage from '../../components/softOnUnitsPage'
import YamlEditor from '../../components/yamlEditor'

function Units() {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const closeDrawer = () => {
    setIsDrawerVisible(false)
  }
  const onAddClickHandle = () => {
    setIsDrawerVisible(true)
  }

  const [editFormState, setEditFormState] = useState({
    isTestUnitFormVisible: false,
    isInheritUnitFormVisible: false,
    isPackageFormVisible: false,
    isFormVisible: false,
  })

  const [id, setId] = useState({
    client_id: null,
    product_id: null,
    unit_id: null,
    package_id: null,
    soft_id: null,
  })

  const { client_id, product_id, soft_id, unit_id, package_id } = id

  const [activeUnitID, setActiveUnitID] = useState(null)

  const [currentPackageItem, setCurrentPackageItem] = useState(null)

  const {
    isFormVisible,
    isPackageFormVisible,
    isInheritUnitFormVisible,
    isTestUnitFormVisible,
  } = editFormState

  const [packagesFormUnits, setPackagesFromUnits] = useState([])

  const [softFormPackages, setSoftFormPackages] = useState([])

  const [isVisible, setIsVisible] = useState({
    products: false,
    units: false,
    packages: false,
    soft: false,
  })

  const { products, units, packages } = isVisible

  const [softwareList, setSoftwareList] = useState([])

  return (
    <div className={`${s.units} unitsPage`}>
      <Drawer
        title='Добавление юнита'
        width='1000px'
        placement='right'
        closable={true}
        onClose={closeDrawer}
        open={isDrawerVisible}
        getContainer={false}
        style={{ backgroundColor: 'lightgray' }}
      >
        <UnitForm closeDrawer={closeDrawer} />
      </Drawer>

      <Row style={{ marginBottom: 15 }}>
        <Col xxl={{ offset: 21 }} xl={{ offset: 20 }} lg={{ offset: 18 }}>
          <Button type='primary' onClick={onAddClickHandle} size='large'>
            <PlusSquareOutlined />
            Добавить юнит
          </Button>
        </Col>
      </Row>

      <ClientsOnUnitsPage
        client_id={client_id}
        setActiveUnitID={setActiveUnitID}
        setId={setId}
        setIsVisible={setIsVisible}
        setSoftFormPackages={setSoftFormPackages}
      />

      <ProductsOnUnitsPage
        setId={setId}
        setIsVisible={setIsVisible}
        client_id={client_id}
        products={products}
        product_id={product_id}
        setPackagesFromUnits={setPackagesFromUnits}
      />

      <UnitsOnUnitsPage
        units={units}
        isFormVisible={isFormVisible}
        product_id={product_id}
        unit_id={unit_id}
        setId={setId}
        setPackagesFromUnits={setPackagesFromUnits}
        setActiveUnitID={setActiveUnitID}
        setIsVisible={setIsVisible}
        setSoftFormPackages={setSoftFormPackages}
        activeUnitID={activeUnitID}
        isInheritUnitFormVisible={isInheritUnitFormVisible}
        isTestUnitFormVisible={isTestUnitFormVisible}
        packagesFormUnits={packagesFormUnits}
        setEditFormState={setEditFormState}
      />

      <PackagesOnUnitsPage
        packages={packages}
        packagesFormUnits={packagesFormUnits}
        setId={setId}
        setSoftFormPackages={setSoftFormPackages}
        setIsVisible={setIsVisible}
        setEditFormState={setEditFormState}
        isPackageFormVisible={isPackageFormVisible}
        package_id={package_id}
        currentPackageItem={currentPackageItem}
        setCurrentPackageItem={setCurrentPackageItem}
        setSoftwareList={setSoftwareList}
        softwareList={softwareList}
      />

      <SoftOnUnitsPage
        soft_id={soft_id}
        setIsVisible={setIsVisible}
        setId={setId}
        softFormPackages={softFormPackages}
      />

      <div className='dividerBox' style={{ marginBottom: 50 }}>
        <Divider orientation='left'>Описание</Divider>
        <p>
          Набор поставляемого софта, под определенного клиента, в рамках конкретного
          продукта
        </p>
      </div>
    </div>
  )
}

export default Units
