import React, { useState } from 'react'
import s from '../../pages/units/units.module.css'
import { CloseOutlined, DatabaseOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Cascader,
  Checkbox,
  Form,
  List,
  Modal,
  Switch,
  Typography,
} from 'antd'
import { Reorder } from 'framer-motion'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Meta from 'antd/es/card/Meta'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'
import { AppStore } from '../../store/store'
import { useSendNotifications } from '../../utils/hooks'
import YamlEditor from '../yamlEditor'

const PackagesOnUnitsPage = ({
  packages,
  packagesFormUnits,
  setId,
  setSoftFormPackages,
  setIsVisible,
  setCurrentPackageItem,
  setSoftwareList,
  package_id,
  setEditFormState,
  isPackageFormVisible,
  currentPackageItem,
  softwareList,
}) => {
  const { Title } = Typography

  const [options, setOptions] = useState([])
  const [gitLabId, setGitLabId] = useState(null)
  const [branchName, setBranchName] = useState(null)

  const modifiedSoftwareList = softwareList?.map((software) => software.value)

  const queryClient = useQueryClient()

  const [form] = Form.useForm()

  const { mutate: inheritPackage } = useMutation(['inherit_package'], {
    mutationFn: (payload) =>
      apiRequest(`${routesList.package}/${package_id}/inherit`).add(payload),
    onSuccess: () => {
      queryClient.invalidateQueries('units')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Пакет отнаследован'
        s.notifications.type = 'success'
      })

      setEditFormState((oldValue) => ({
        ...oldValue,
        isPackageFormVisible: false,
      }))
    },
    onError: (error) => {
      useSendNotifications.error(error)
    },
  })

  const inheritPackages = () => {
    const modifiedSoftwareList = softwareList?.map((el, index) => ({
      software_id: el.value,
      order: index,
    }))

    inheritPackage({ software: modifiedSoftwareList })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const switchOnChange = (checked) => {}

  const packagesItems = packagesFormUnits?.map((el) => {
    return (
      <List.Item key={el.id}>
        <Card
          onClick={() => {
            setId((oldValue) => ({ ...oldValue, package_id: el.id }))
            setSoftFormPackages(el.packageitems)
            setIsVisible((oldValue) => ({ ...oldValue, softItem: false }))
            setCurrentPackageItem(el)

            setSoftwareList(
              el?.packageitems?.map((el) => {
                return {
                  label: `${el.software.name} ${el.software.branch} ${el.software.version}`,
                  value: el.software_id,
                }
              }),
            )
          }}
          className={s.cardInfo}
          style={{ backgroundColor: package_id === el.id ? '#c1c5cb' : '' }}
        >
          <div className={s.cardInfo}>
            <Meta
              title={`${el.name} || id: ${el.inner_id}`}
              description={el.description}
            />
            {package_id === el.id && (
              <div className={s.buttonsBox}>
                {/*<Switch*/}
                {/*  unCheckedChildren='Закрыто'*/}
                {/*  checkedChildren='Открыто'*/}
                {/*  checked={!!isModalOpen}*/}
                {/*  onChange={(checked) => {*/}
                {/*    setIsModalOpen(checked)*/}
                {/*  }}*/}
                {/*/>*/}
                <Button
                  onClick={() => {
                    setEditFormState((oldValue) => ({
                      ...oldValue,
                      isPackageFormVisible: true,
                    }))
                  }}
                >
                  Наследовать
                </Button>
              </div>
            )}
          </div>
        </Card>
      </List.Item>
    )
  })

  useQuery(['soft_list'], () => apiRequest(routesList.software).get(), {
    onSuccess: (data) => {
      if (data) {
        setOptions(
          data?.items?.map((soft) => {
            return {
              label: soft.name,
              value: soft.gitlab_id,
              isLeaf: false,
            }
          }),
        )
      }
    },
  })

  useQuery(
    ['get_branches_list', gitLabId],
    () => apiRequest(`${routesList.software}/${gitLabId}/branches`).get(),
    {
      enabled: !!gitLabId,
      onSuccess: (data) => {
        if (data) {
          setOptions((oldValue) =>
            oldValue?.map((item) => {
              return {
                ...item,
                children: data?.items.map((item1) => {
                  return {
                    label: item1.branch,
                    value: item1.branch,
                    children: [
                      {
                        value: 'zhejiang',
                        label: 'Zhejiang',
                      },
                    ],
                  }
                }),
              }
            }),
          )
        }
      },
    },
  )

  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

  useQuery(
    ['get_versions_list', gitLabId, branchName],
    () => apiRequest(`/software/${gitLabId}/branches/${branchName}/versions`).get(),
    {
      enabled: !!gitLabId && !!branchName,
      onSuccess: (data) => {
        setOptions((oldValue) =>
          oldValue?.map((el) => {
            return {
              ...el,
              children: el.children?.map((el1) => {
                return {
                  ...el1,
                  children: data?.items
                    ?.map((el2) => {
                      return {
                        label: el2.version,
                        value: JSON.stringify(el2),
                        isLeaf: false,
                        children: el2.patches?.map((el3) => {
                          return {
                            label: el3.id,
                            value: el3.id,
                            disabled: modifiedSoftwareList?.includes(el3.id),
                          }
                        }),
                      }
                    })
                    .sort((a, b) => collator.compare(b.label, a.label)),
                }
              }),
            }
          }),
        )
      },
    },
  )

  const onChange = (valueArr, value) => {
    if (valueArr.length === 1) {
      setGitLabId(valueArr[0])
      setBranchName(null)
      return
    }

    if (valueArr.length >= 2) {
      setBranchName(valueArr[1])
    }

    let index = 0

    if (valueArr.length === 4) {
      setSoftwareList((oldValue) => [
        ...oldValue,
        {
          value: valueArr[3],
          label: `${value[0].label}-${value[1].label}-${value[2].label}-${value[3].label}`,
          order: index,
        },
      ])
    }

    index++
  }

  return (
    <>
      {/*<Modal*/}
      {/*  title='YAML Редактор'*/}
      {/*  open={isModalOpen}*/}
      {/*  width='1000'*/}
      {/*  onOk={() => {*/}
      {/*    setIsModalOpen(false)*/}
      {/*  }}*/}
      {/*  onCancel={() => {*/}
      {/*    setIsModalOpen(false)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <YamlEditor />*/}
      {/*</Modal>*/}

      {packages && (
        <div className='units__box'>
          <Title style={{ color: '#505962' }} level={2}>
            <DatabaseOutlined /> Пакеты
          </Title>
          {!isPackageFormVisible ? (
            <List
              grid={{ gutter: [4, 16], xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 4 }}
              dataSource={packagesItems}
              renderItem={(item) => item}
            />
          ) : (
            <div className={s.editForm}>
              <Button
                type='primary'
                icon={<CloseOutlined />}
                className={s.closeButton}
                onClick={() => {
                  setEditFormState((oldValue) => ({
                    ...oldValue,
                    isPackageFormVisible: false,
                  }))
                  setIsVisible((oldValue) => ({ ...oldValue, softItem: false }))
                }}
              />

              <Title level={4}>Наследование пакета {currentPackageItem.name}</Title>

              <Form
                name='inheritPackages'
                layout='vertical'
                form={form}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                autoComplete='off'
                onFinish={inheritPackages}
              >
                <Form.Item>
                  <Cascader
                    options={options}
                    value={null}
                    placeholder='Выберите софт'
                    onChange={onChange}
                    changeOnSelect
                    style={{ width: '100%' }}
                  />
                </Form.Item>

                {softwareList.length > 0 && (
                  <Reorder.Group
                    onReorder={(values) => {
                      setSoftwareList(values)
                    }}
                    values={softwareList}
                    axis='y'
                    style={{
                      marginBottom: 20,
                      display: 'flex',
                      rowGap: 5,
                      flexDirection: 'column',
                    }}
                  >
                    {softwareList?.map((el, index) => {
                      el.order = index
                      return (
                        <div
                          key={el.value}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            columnGap: 15,
                            maxWidth: 300,
                            width: '100%',
                          }}
                        >
                          <Reorder.Item
                            value={el}
                            style={{
                              cursor: 'pointer',
                              fontSize: 14,
                              display: 'flex',
                              rowGap: 5,
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              columnGap: 15,
                              borderRadius: '5px',
                              backgroundColor: '#FFFFFF',
                              padding: 5,
                              maxWidth: 380,
                              width: '100%',
                            }}
                          >
                            <span>
                              {index + 1}) {el.label}
                            </span>

                            <Button
                              danger
                              type='primary'
                              onClick={(e) => {
                                e.preventDefault()
                                setSoftwareList((oldValue) =>
                                  oldValue.filter((item) => item.value !== el.value),
                                )
                              }}
                            >
                              x
                            </Button>
                          </Reorder.Item>
                        </div>
                      )
                    })}
                  </Reorder.Group>
                )}

                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Button type='primary' htmlType='submit'>
                    Добавить
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default PackagesOnUnitsPage
