import React from 'react'
import s from './commandInfo.module.css'
import ItemsList from '../itemsList'
import InfoItem from '../infoItem/InfoItem'
import AgentInfo from '../agentInfo'
import { Typography } from 'antd'

const CommandInfo = ({ id, info }) => {
  const { Title } = Typography

  return (
    <ItemsList>
      <InfoItem title='Название комманды' name={info.command} />
      <InfoItem title='Прочитано' name={info.readed} />

      <Title level={4}>Информация об агенте</Title>
      <AgentInfo info={info.agent} />
    </ItemsList>
  )
}

export default CommandInfo
