import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { Button, Collapse, Descriptions, Form, Input, Select, Typography } from 'antd'
import EditableInfo from '../editableInfo/editableInfo'
import CustomSelect from '../customSelect'
import { AppStore } from '../../store/store'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const AgentInfo = ({ info, id }) => {
  const { Panel } = Collapse

  const { data: agentItem } = useQuery(
    ['get_agent_item', id],
    () => apiRequest(routesList.agent).getItem(id),
    {
      enabled: !!id,
    },
  )

  const agentInfo = info ? info : agentItem

  const [form] = Form.useForm()

  const queryClient = useQueryClient()

  const { mutate: editAgent, isLoading: isEditLoading } = useMutation(['edit_agent'], {
    mutationFn: ({ id, payload }) => apiRequest(routesList.agent).update({ id, payload }),
    onSuccess: () => {
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Информация об агенте обновлена'
        s.notifications.type = 'success'
      })
      queryClient.invalidateQueries('agent_list')
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при обновлении информации об агенте'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const [selectValue, setSelectValue] = useState({
    dev: '',
  })

  const { dev } = selectValue

  const selectHandle = (name, value) => {
    setSelectValue((oldData) => ({ ...oldData, [name]: value }))
  }

  useEffect(() => {
    if (agentInfo) {
      const { name, description, dev } = agentInfo

      setInputValue((oldValue) => ({ ...oldValue, name, description }))
      setSelectValue((oldValue) => ({ ...oldValue, dev }))
    }
  }, [agentInfo])

  const [inputDisabled, setInputDisabled] = useState(true)

  const [inputValue, setInputValue] = useState({
    name: '',
    description: '',
  })

  const { name, description } = inputValue

  const onChange = (e) => {
    const { name, value } = e.currentTarget

    setInputValue((oldValue) => ({ ...oldValue, [name]: value }))
  }

  const { Title } = Typography

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 10,
          maxWidth: 400,
          width: '100%',
        }}
      >
        <EditableInfo
          placeholder='Введите название'
          value={name}
          isDisabled={inputDisabled}
          setInputDisabled={setInputDisabled}
          onChange={onChange}
          name='name'
          onClickHandle={() => {
            editAgent({ id, payload: { name } })
          }}
          isLoading={isEditLoading}
        />

        <EditableInfo
          placeholder='Введите описание'
          value={description}
          isDisabled={inputDisabled}
          setInputDisabled={setInputDisabled}
          onChange={onChange}
          name='description'
          onClickHandle={() => {
            editAgent({ id, payload: { description } })
          }}
          isLoading={isEditLoading}
        />

        <CustomSelect
          options={[
            { value: true, label: 'У нас' },
            { value: false, label: 'У клиента' },
          ]}
          onChange={selectHandle}
          value={dev}
          placeholder='Выберите где развернут агент'
          name='dev'
          onClickHandle={() => {
            editAgent({ id, payload: { dev } })
          }}
        />
      </div>

      <Descriptions column={1}>
        <Descriptions.Item label='Система'>{agentInfo?.system}</Descriptions.Item>

        <Descriptions.Item label='Пакетный менеджер'>
          {agentInfo?.pkg_system}
        </Descriptions.Item>

        <Descriptions.Item label='Локальный адрес'>
          {agentInfo?.local_address}
        </Descriptions.Item>

        <Descriptions.Item label='Ключ агнета'>{agentInfo?.secret}</Descriptions.Item>
      </Descriptions>

      <Title level={4}>Информация об установленных пакетах</Title>

      {info?.installed_packages?.length > 0 && (
        <Collapse>
          {info?.installed_packages?.map((software, index) => {
            return (
              <Panel key={software.id} header={`Пакет номер ${index + 1}`}>
                <Descriptions column={1}>
                  <Descriptions.Item label='Название'>{software?.name}</Descriptions.Item>

                  <Descriptions.Item label='Описание'>
                    {software?.description}
                  </Descriptions.Item>

                  <Descriptions.Item label='Заморожен'>
                    {software?.freezed ? 'Да' : 'Нет'}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            )
          })}
        </Collapse>
      )}

      {info?.installed_packages?.length <= 0 && 'Нет привязанных пакетов'}
    </>
  )
}

export default AgentInfo
