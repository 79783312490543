import React, { useState } from 'react'
import s from './customSelect.module.css'
import { Button, Select, Space } from 'antd'

const CustomSelect = ({ options, onChange, value, placeholder, name, onClickHandle }) => {
  const [inputDisabled, setInputDisabled] = useState(true)

  return (
    <Space.Compact size='large'>
      <Select
        value={value}
        placeholder={placeholder}
        onChange={(value) => {
          onChange(name, value)
        }}
        options={options}
        className={s.input}
        disabled={inputDisabled}
        size='large'
      />

      <Button
        size='large'
        onClick={() => {
          setInputDisabled((oldValue) => !oldValue)

          if (!inputDisabled && onClickHandle) {
            onClickHandle()
          }
        }}
      >
        {inputDisabled ? 'Редактировать' : 'Сохранить'}
      </Button>
    </Space.Compact>
  )
}

export default CustomSelect
