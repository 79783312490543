import React from 'react'
import { Button, Descriptions, Input, Typography } from 'antd'
import { useQuery } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'
import { convertDateWithMoment } from '../../utils/hooks'
import { HighlightOutlined } from '@ant-design/icons'

const LicenseInfo = ({ id, info, licenseKey, getKeyLicense }) => {
  const { data: licenseData } = useQuery(
    ['get_license_item_info', id],
    () => apiRequest(routesList.license).getItem(id),
    {
      enabled: !!id,
    },
  )

  const licenseItemInfo = id ? licenseData : info

  const { Paragraph } = Typography

  return (
    <>
      <Descriptions title='Информация о лицензии' column={1}>
        <Descriptions.Item label='Клиент'>
          {licenseItemInfo?.client?.name}
        </Descriptions.Item>

        <Descriptions.Item label='Продукт'>
          {licenseItemInfo?.product?.name}
        </Descriptions.Item>

        <Descriptions.Item label='Софт'>
          {licenseItemInfo?.software?.name}
        </Descriptions.Item>

        <Descriptions.Item label='Дата создания'>
          {convertDateWithMoment(licenseItemInfo?.created_at)}
        </Descriptions.Item>
      </Descriptions>

      <Button
        style={{ marginBottom: 20 }}
        size='large'
        type='primary'
        onClick={() => {
          getKeyLicense(id)
        }}
      >
        Получить ключ
      </Button>

      <Paragraph>{licenseKey}</Paragraph>
    </>
  )
}

export default LicenseInfo
