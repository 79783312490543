/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { AppStore } from '../../store/store'
import routesList from '../../server/routesList.json'

const ClientForm = ({ id, closeDrawer }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const { mutate: addClient } = useMutation(['add_client'], {
    mutationFn: (payload) => apiRequest(routesList.clients).add(payload),
    onSuccess: () => {
      queryClient.invalidateQueries('clients_list')

      form.resetFields()

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Клиент добавлен'
        s.notifications.type = 'success'
      })

      closeDrawer()
    },
    onError: () => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при добавлении клиента'
        s.notifications.type = 'error'
      })
    },
  })

  const { mutate: updateClients } = useMutation(['update_clients_info'], {
    mutationFn: (payload) => apiRequest(routesList.clients).update({ id, payload }),
    onSuccess: () => {
      queryClient.invalidateQueries('clients_list')
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Информация о клиенте обновлена'
        s.notifications.type = 'success'
      })
      closeDrawer()
    },
  })

  useQuery(
    ['get_client_item_info', id],
    () => apiRequest(routesList.clients).getItem(id),
    {
      enabled: !!id,
      onSuccess: (data) => {
        form.setFieldsValue(data)
      },
    },
  )

  const onSubmit = (payload) => {
    if (!id) {
      addClient(payload)
      return
    }

    if (id) {
      updateClients(payload)
      return
    }
  }

  return (
    <Form
      name='add_client'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      <Form.Item
        label='Наименование клиента'
        name='name'
        rules={[
          {
            required: true,
            message: 'Введите наименование клиента',
            max: 50,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Описание клиента'
        name='description'
        rules={[
          {
            required: true,
            message: 'Введите описание клиента',
            max: 50,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label='Ссылка на картинку' name='avatar'>
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ClientForm
