import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import s from './cardsList.module.css'
import {
  Button,
  Card,
  Col,
  Drawer,
  Modal,
  Pagination,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd'
import {
  ApartmentOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  InfoOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { AppStore } from '../../store/store'

const CardsList = ({
  data,
  title,
  drawerTitle,
  deleteItem,
  getAddForm,
  getEditForm,
  getInfo,
  dataLength,
  legacy,
  deletedItem,
  isLoading,
}) => {
  const { Title } = Typography

  const [itemId, setItemId] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [rowData, setRowData] = useState(null)

  const [open, setOpen] = useState(false)

  const [drawerState, setDrawerState] = useState('')

  const limit = AppStore.useState((s) => s.pagination.limit)

  const [currentPage, setCurrentPage] = useState(1)

  const paginationChange = (page) => {
    setCurrentPage(page)
    AppStore.update((s) => {
      s.pagination.offset = (page - 1) * limit
    })
  }

  const getDrawerContent = (state) => {
    switch (state) {
      case 'info':
        return getInfo(itemId)
      case 'add':
        return getAddForm()
      case 'edit':
        return getEditForm(itemId, rowData)
      default:
        return 'Nothing'
    }
  }

  const gridStyle = {
    width: '100%',
    textAlign: 'center',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
    setDrawerState('')
    AppStore.update((s) => {
      s.units.legacyMode = false
    })
  }
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    deleteItem(itemId)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const getCardItems = (el, legacyMode) => {
    const cardItems = [
      <Popover key='edit' title='Редактирование'>
        <EditOutlined
          style={{ color: '#000000' }}
          onClick={() => {
            setItemId(el.id)
            setDrawerState('edit')
            showDrawer()
          }}
        />
      </Popover>,

      <Popover key='info' title='Информация'>
        <ExclamationCircleOutlined
          style={{ color: '#000000' }}
          onClick={() => {
            setItemId(el.id)
            setDrawerState('info')
            showDrawer()
          }}
        />
      </Popover>,
      <Popover key='legacy' title='Наследование'>
        <ApartmentOutlined
          style={{ color: '#000000' }}
          onClick={() => {
            AppStore.update((s) => {
              s.units.legacyMode = true
            })
            setItemId(el.id)
            setDrawerState('edit')
            showDrawer()
          }}
        />
      </Popover>,
      <Popover key='delete' title='Удаление'>
        <DeleteOutlined
          style={{ color: '#ad2020' }}
          onClick={() => {
            setItemId(el.id)
            showModal()
          }}
        />
      </Popover>,
    ]

    if (!!legacyMode) {
      return cardItems
    }

    return cardItems.filter((el) => el.key !== 'legacy')
  }

  const dataContent = data?.map((el) => (
    <Card
      key={uuidv4()}
      title={el.name}
      size='default'
      className={s.card}
      actions={getCardItems(el, legacy)}
      onClick={() => {
        setRowData(el)
      }}
    >
      <Card.Grid style={gridStyle} className={s.cardContent}>
        <p className={s.cardText}>
          {el?.description ? el?.description : 'Описание отсутствует'}
        </p>
      </Card.Grid>
    </Card>
  ))

  return (
    <>
      <Row>
        <Col span={24}>
          <Title style={{ color: '#505962' }} level={2}>
            {title}
          </Title>
        </Col>
      </Row>

      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <Button
            type='primary'
            style={{ backgroundColor: '#3d3f41' }}
            onClick={() => {
              showDrawer()
              setDrawerState('add')
            }}
          >
            Добавить
          </Button>
        </Col>
      </Row>

      <Row style={{ marginBottom: 15, gap: 20 }} wrap>
        {dataContent}
      </Row>

      {isLoading && (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin size='large' />
        </Row>
      )}

      {dataLength > 0 && (
        <Row>
          <Col span={24}>
            <Pagination
              current={currentPage}
              total={dataLength}
              onChange={paginationChange}
              pageSize={limit}
              showSizeChanger={false}
            />
          </Col>
        </Row>
      )}

      <Drawer
        title={drawerTitle}
        placement='right'
        closable={true}
        onClose={onClose}
        open={open}
        getContainer={false}
        style={{ backgroundColor: 'lightgray' }}
        size='large'
      >
        {getDrawerContent(drawerState)}
      </Drawer>

      <Modal title='Удаление' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Удалить {deletedItem ? deletedItem : null} ? </p>
      </Modal>
    </>
  )
}

export default CardsList
