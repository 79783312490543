import React, { useState } from 'react'
import s from '../../pages/units/units.module.css'
import { CloseOutlined, CodeOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { Button, Card, Descriptions, List, Tooltip, Typography } from 'antd'
import UnitSentTest from '../unitSentTestForm'
import InheritUnitForm from '../inheritUnitForm'
import arrowImg from '../../assets/images/arrow.png'
import { getUnitStatus } from '../../utils/getValues'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'
import { filterUnits } from '../../utils/units'
import { AppStore } from '../../store/store'
import { useHorizontalScroll, useSendNotifications } from '../../utils/hooks'

const UnitsOnUnitsPage = ({
  units,
  isFormVisible,
  product_id,
  unit_id,
  setId,
  setPackagesFromUnits,
  setActiveUnitID,
  setSoftFormPackages,
  setIsVisible,
  activeUnitID,
  setEditFormState,
  isTestUnitFormVisible,
  isInheritUnitFormVisible,
  packagesFormUnits,
}) => {
  const queryClient = useQueryClient()

  const { Title } = Typography

  const [allPackagesForUnitInherit, setAllPackagesForUnitInherit] = useState([])

  useQuery(['packages_for_unit'], () => apiRequest(routesList.package).get(), {
    onSuccess: (data) => {
      const packagesForSelect = data?.items
        ?.filter((item) => !item.freezed)
        ?.map((el) => ({
          value: el?.id,
          label: `${el?.name} || id: ${el.inner_id}`,
        }))

      setAllPackagesForUnitInherit((oldValue) => [...oldValue, ...packagesForSelect])
    },
  })

  const { mutate: approveUnit } = useMutation(['approve_unit'], {
    mutationFn: (unitID) =>
      apiRequest(routesList.unit).update({ id: `${unitID}/approve`, payload: {} }),
    onSuccess: () => {
      queryClient.invalidateQueries('units')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Юнит подтвержден'
        s.notifications.type = 'success'
      })
    },
    onError: (error) => {
      useSendNotifications.error(error)
    },
  })

  const { mutate: rejectUnit } = useMutation(['reject_unit'], {
    mutationFn: (unitID) =>
      apiRequest(routesList.unit).update({ id: `${unitID}/reject`, payload: {} }),
    onSuccess: () => {
      queryClient.invalidateQueries('units')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Юнит отклонен'
        s.notifications.type = 'success'
      })
    },
    onError: (error) => {
      useSendNotifications.error(error)
    },
  })

  const [sortedUnits, setSortedUnits] = useState([])

  useQuery(
    ['units', product_id],
    () => apiRequest(routesList.unit).get(0, 100, { product_id, order_by: '-id' }),
    {
      enabled: !!product_id,
      onSuccess: (data) => {
        if (data && data.items) {
          const sortedResult = filterUnits(data.items)

          setSortedUnits(sortedResult)
        }
      },
    },
  )

  const unitsItems = sortedUnits?.map((el1) => {
    return el1
      .map((el, index, array) => {
        return (
          <List.Item
            key={el.id}
            className='unit_card_arrow'
            style={{ height: '100%', width: 300, position: 'relative' }}
          >
            {array.length > 1 && (
              <img
                src={arrowImg}
                style={{ display: index === 0 ? 'none' : 'block' }}
                className={s.arrowImg}
                alt={`${index}стрелка`}
              />
            )}

            <Card
              className={s.cardInfo}
              onClick={() => {
                setId((oldValue) => ({ ...oldValue, unit_id: el.id, package_id: null }))
                setPackagesFromUnits(el.packages)
                setActiveUnitID(el.id)
                setSoftFormPackages([])
                setIsVisible((oldValue) => ({
                  ...oldValue,
                  packages: true,
                  soft: false,
                  softItem: false,
                }))
              }}
              style={{ backgroundColor: unit_id === el.id ? '#c1c5cb' : '', width: 300 }}
              bodyStyle={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div className={s.cardInfo}>
                <Descriptions column={1}>
                  <Descriptions.Item label='ID'>{el?.id}</Descriptions.Item>

                  <Descriptions.Item label='Статус'>
                    {getUnitStatus(el.status)}
                  </Descriptions.Item>

                  <Descriptions.Item label='Описание' className={s.description_text}>
                    <Tooltip placement='left' title={el?.description}>
                      {el.description ? el.description : 'Описание отсутствует'}
                    </Tooltip>
                  </Descriptions.Item>
                </Descriptions>

                {activeUnitID === el.id && (
                  <div className={s.buttonsBox}>
                    <Button
                      onClick={() => {
                        setEditFormState((oldValue) => ({
                          ...oldValue,
                          isFormVisible: true,
                          isInheritUnitFormVisible: true,
                        }))
                      }}
                    >
                      Наследовать
                    </Button>

                    {el.status === 'created' && (
                      <Button
                        onClick={() => {
                          setEditFormState((oldValue) => ({
                            ...oldValue,
                            isFormVisible: true,
                            isTestUnitFormVisible: true,
                          }))
                        }}
                      >
                        Отправить на тест
                      </Button>
                    )}

                    {el.status === 'test' && (
                      <>
                        <Button
                          onClick={() => {
                            approveUnit(el.id)
                          }}
                        >
                          Принять
                        </Button>
                        <Button
                          onClick={() => {
                            rejectUnit(el.id)
                          }}
                        >
                          Отклонить
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </Card>
          </List.Item>
        )
      })
      .reverse()
  })

  const listRef = useHorizontalScroll()

  return (
    <>
      {units && (
        <div className='units__box'>
          <Title style={{ color: '#505962' }} level={2}>
            <CodeOutlined /> Юниты
          </Title>

          {!isFormVisible ? (
            <>
              {unitsItems?.map((el, index) => {
                return (
                  <div
                    key={uuidv4()}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 20,
                      position: 'relative',
                    }}
                  >
                    <h3>Цепочка {index + 1}</h3>

                    <ul
                      key={uuidv4()}
                      style={{
                        display: 'flex',
                        columnGap: 40,
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        whiteSpace: 'nowrap',
                        paddingLeft: '28px',
                        paddingRight: '28px',
                      }}
                    >
                      {el}
                    </ul>
                  </div>
                )
              })}

              {}
            </>
          ) : (
            <div className={s.editForm}>
              <Button
                type='primary'
                icon={<CloseOutlined />}
                className={s.closeButton}
                onClick={() => {
                  setEditFormState((oldValue) => ({
                    ...oldValue,
                    isTestUnitFormVisible: false,
                    isFormVisible: false,
                    isInheritUnitFormVisible: false,
                  }))
                }}
              />

              {isTestUnitFormVisible && (
                <UnitSentTest
                  unitID={activeUnitID}
                  packagesFormUnits={packagesFormUnits}
                  closeForm={() => {
                    setEditFormState((oldValue) => ({
                      ...oldValue,
                      isTestUnitFormVisible: false,
                      isFormVisible: false,
                      isInheritUnitFormVisible: false,
                    }))
                  }}
                />
              )}

              {isInheritUnitFormVisible && (
                <InheritUnitForm
                  // allPackagesForUnitInherit={allPackagesForUnitInherit}
                  currentPackages={packagesFormUnits}
                  allPackagesForUnitInherit={allPackagesForUnitInherit}
                  unit_id={unit_id}
                  setEditFormState={setEditFormState}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default UnitsOnUnitsPage
