import Cookies from 'js-cookie'
import { routesPath } from '../routing/path'

export const getToken = () => {
  return Cookies.get('token') || ''
}

export const removeToken = () => {
  return Cookies.remove('token')
}

const handleLogout = () => {
  removeToken()
  window.location.href = routesPath.login
  localStorage.removeItem('username')
}
