import React from 'react'
import ItemsList from '../itemsList'
import InfoItem from '../infoItem/InfoItem'
import { useQuery } from 'react-query'
import { apiClient } from '../../server/ApiClient'

const DeployJobInfo = ({ id, info }) => {
  const { data: deployJobItem } = useQuery(
    ['get_deploy_job_item', id],
    () => apiClient.getDeployJobItem(id),
    {
      enabled: !!id,
    },
  )

  const deployJobInfo = info ? info : deployJobItem

  return (
    <ItemsList>
      <InfoItem title='Команда' name={deployJobInfo?.command} />
      <InfoItem title='Название агента' name={deployJobInfo?.agent?.name} />
      <InfoItem title='Описание агента' name={deployJobInfo?.agent?.description} />
      <InfoItem title='dev' name={deployJobInfo?.agent?.dev ? 'true' : 'false'} />
      <InfoItem title='Название системы' name={deployJobInfo?.agent?.system} />
      <InfoItem title='pkg_system' name={deployJobInfo?.agent?.pkg_system} />
      <InfoItem title='proxy_over_id' name={deployJobInfo?.agent?.proxy_over_id} />
      <InfoItem title='local_address' name={deployJobInfo?.agent?.local_address} />
      <InfoItem title='secret' name={deployJobInfo?.agent?.secret} />
    </ItemsList>
  )
}

export default DeployJobInfo
