import React from 'react'
import { useQuery } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { Descriptions, Typography } from 'antd'
import UnitInfo from '../unitInfo/UnitInfo'
import { v4 as uuidv4 } from 'uuid'
import routesList from '../../server/routesList.json'

const ProductInfo = ({ id, info }) => {
  const { Title } = Typography

  const { data: productInfo } = useQuery(
    ['get_pr_item_page_info', id],
    () => apiRequest(routesList.product).getItem(id),
    {
      enabled: !!id,
    },
  )

  const productItemInfo = id ? productInfo : info

  return (
    <>
      <Descriptions title='Информация о продукте' column={1}>
        <Descriptions.Item label='Название'>{productItemInfo?.name}</Descriptions.Item>

        <Descriptions.Item label='Описание'>
          {productItemInfo?.description}
        </Descriptions.Item>
      </Descriptions>

      {productItemInfo?.units?.length > 0 && (
        <Title level={5}>Юниты входящие в продукт:</Title>
      )}

      {productItemInfo?.units?.map((el, index) => {
        return (
          <React.Fragment key={uuidv4()}>
            <Title level={5}>Юнит №{index + 1}</Title>
            <UnitInfo key={el.id} info={el} />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ProductInfo
