import { convertDateWithMoment } from '../utils/hooks'
import { Tag } from 'antd'

export const commandColumns = [
  {
    title: 'Название',
    dataIndex: 'command',
    key: 'command',
  },
  {
    title: 'Прочитано',
    dataIndex: 'readed',
    key: 'readed',
    render: (readed) =>
      readed ? (
        <Tag color='green'>Прочитано</Tag>
      ) : (
        <Tag color='geekblue'>Не прочитано</Tag>
      ),
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) => convertDateWithMoment(created_at),
  },
]
