import React, { useState } from 'react'
import { Button, Cascader, Form, Select, Typography } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'
import { AppStore } from '../../store/store'
import { Reorder } from 'framer-motion'
import { useSendNotifications } from '../../utils/hooks'

const InheritUnitForm = ({
  unit_id,
  setEditFormState,
  allPackagesForUnitInherit,
  currentPackages,
}) => {
  const queryClient = useQueryClient()

  const modifiedCurrentPackages = currentPackages.map((el) => {
    return { value: el.id, label: `${el.name} || id: ${el.inner_id}` }
  })

  const { Title } = Typography

  const { mutate: inheritUnit } = useMutation(['inherit_unit'], {
    mutationFn: (payload) =>
      apiRequest(`${routesList.unit}/${unit_id}/inherit`).add(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries('units')

      await queryClient.invalidateQueries('packages_for_unit')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Юнит отнаследован'
        s.notifications.type = 'success'
      })

      setEditFormState((oldValue) => ({
        ...oldValue,
        isTestUnitFormVisible: false,
        isFormVisible: false,
        isInheritUnitFormVisible: false,
      }))
    },
    onError: (error) => {
      useSendNotifications.error(error)
    },
  })

  const [options, setOptions] = useState([])

  const onChange = (valueArr, value) => {
    let index = 0

    setOptions((oldValue) => [
      ...oldValue,
      { value: value?.[0]?.value, label: value?.[0]?.label, order: index },
    ])

    index++
  }

  const idValuesFromPackages = allPackagesForUnitInherit.map((el) => el.value)

  const idValuesFromOptions = options.map((el) => el.value)

  const modifiedAllPackagesForUnitInherit = [
    ...allPackagesForUnitInherit,
    ...modifiedCurrentPackages,
  ].filter((el) => !idValuesFromOptions.includes(el.value))

  const inheritUnits = (payload) => {
    inheritUnit({ packages_id: idValuesFromOptions })
  }

  return (
    <Form name='packages' autoComplete='off' onFinish={inheritUnits}>
      <Title level={3}>Наследование юнита - {unit_id}</Title>
      {/*<Form.Item label='Пакеты' name='packages_id'>*/}
      {/*  <Select*/}
      {/*    placeholder='Выберите пакеты'*/}
      {/*    allowClear*/}
      {/*    options={allPackagesForUnitInherit}*/}
      {/*    mode='multiple'*/}
      {/*  />*/}
      {/*</Form.Item>*/}

      <Form.Item>
        <Cascader
          options={modifiedAllPackagesForUnitInherit}
          value={null}
          placeholder='Выберите пакеты'
          onChange={onChange}
          changeOnSelect
          style={{ width: '100%' }}
        />

        {options.length > 0 && (
          <Reorder.Group
            onReorder={(values) => {
              setOptions(values)
            }}
            values={options}
            axis='y'
            style={{
              marginBottom: 20,
              display: 'flex',
              rowGap: 5,
              flexDirection: 'column',
            }}
          >
            {options?.map((el, index) => {
              el.order = index
              return (
                <div
                  key={el.value}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    columnGap: 15,
                    maxWidth: 300,
                    width: '100%',
                  }}
                >
                  <Reorder.Item
                    value={el}
                    style={{
                      cursor: 'pointer',
                      fontSize: 14,
                      display: 'flex',
                      rowGap: 5,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      columnGap: 15,
                      borderRadius: '5px',
                      backgroundColor: '#FFFFFF',
                      padding: 5,
                      maxWidth: 380,
                      width: '100%',
                    }}
                  >
                    <span>
                      {index + 1}) {el.label}
                    </span>

                    <Button
                      danger
                      type='primary'
                      onClick={(e) => {
                        e.preventDefault()
                        setOptions((oldValue) =>
                          oldValue.filter((item) => item.value !== el.value),
                        )
                      }}
                    >
                      x
                    </Button>
                  </Reorder.Item>
                </div>
              )
            })}
          </Reorder.Group>
        )}
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Отправить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default InheritUnitForm
