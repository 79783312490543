export const routesPath = {
  login: '/login',
  clients: '/clients',
  license: '/license',
  soft: '/soft',
  project: '/project',
  units: '/units',
  packages: '/package',
  'packages-history': '/packages-history',
  products: '/products',
  'deploy-job': '/deploy-job',
  agent: '/agent',
  commands: '/commands',
  'main-agent': '/main-agent',
  'free-packages': '/free-packages',
  users: '/users',
}
