import axios from 'axios'
import { removeToken } from '../utils/authorization'

axios.defaults.baseURL = '/api/v1'

export class MainAPI {
  offset = 0

  limit = 200

  filters = {}

  route = ''

  options = {}

  constructor(route, offset, limit, filters, options) {
    this.route = route
    this.offset = offset
    this.limit = limit
    this.filters = filters
    this.options = options
  }

  async get(offset, limit, filters, options) {
    return axios
      .get(`${this.route}`, {
        params: {
          offset,
          limit,
          ...filters,
        },
        ...options,
      })
      .then((res) => res.data)
  }

  async getItem(id) {
    return axios.get(`${this.route}/${id}`).then((res) => res.data)
  }

  async add(payload = {}, config = {}) {
    return axios.post(`${this.route}`, payload, config)
  }

  async update(data) {
    return axios.patch(`${this.route}/${data.id}`, data.payload)
  }

  async delete(id) {
    return axios.delete(`${this.route}/${id}`)
  }
}

export function apiRequest(route) {
  return new MainAPI(route)
}

axios.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    if (error.response.status === 401 || error.response.statusText === 'Unauthorized') {
      removeToken()

      window.location.href = `${window.location.protocol}//${window.location.host}/login`
    }

    return Promise.reject(error)
  },
)

export const apiClient = {
  async login(data) {
    return axios
      .post(`/auth`, data, {
        withCredentials: true,
      })
      .then((res) => res.data)
  },
  async getProjectList(offset = 0, limit = 200) {
    return axios
      .get(`/project`, { params: { order_by: '-id', limit, offset } })
      .then((res) => res.data)
  },
  async getProjectItem(id) {
    return axios.get(`/project/${id}`).then((res) => res.data)
  },
  async addProject(payload) {
    return axios.post(`/project`, payload)
  },
  async deleteProjectItem(id) {
    return axios.delete(`/project/${id}`)
  },
  async updateProjectItem(data) {
    return axios.patch(`/project/${data.id}`, data.payload)
  },
  async getDeployJobList(offset = 0, limit = 200) {
    return axios
      .get(`/deploy_job`, { params: { order_by: '-id', limit, offset } })
      .then((res) => res.data)
  },
  async getDeployJobItem(id) {
    return axios.get(`/deploy_job/${id}`).then((res) => res.data)
  },
  async downloadBuild(id, config) {
    return axios
      .get(`/download/build/${id}`, {
        responseType: 'blob',
        ...config,
      })
      .then((res) => res.data)
  },
}
