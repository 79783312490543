import { convertDateWithMoment } from '../utils/hooks'
import { Button, Popover, Tag } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import DeletePopoverContent from '../components/deletePopoverContent'
import React from 'react'

export const getUserColumns = (deleteUser) => {
  const usersColumns = [
    {
      title: 'Логин',
      dataIndex: 'login',
      key: 'login',
      render: (data) => <Tag color='geekblue'>{data}</Tag>,
    },
    {
      title: 'ФИО',
      dataIndex: 'fio',
      key: 'fio',
      render: (_, allData) => {
        if (allData.name || allData.surname || allData.patronymic) {
          return `${allData.name ? allData.name : ''} ${
            allData.surname ? allData.surname : ''
          } ${allData.patronymic ? allData.patronymic : ''}`
        }

        return 'Нет данных'
      },
    },
    {
      title: 'Последний вход',
      dataIndex: 'last_login',
      key: 'last_login',
      render: (last_login) => {
        if (last_login) {
          return convertDateWithMoment(last_login)
        }
        return 'Нет данных'
      },
    },
    {
      title: 'Последняя активность',
      dataIndex: 'last_active',
      key: 'last_active',
      render: (last_active) => {
        return convertDateWithMoment(last_active)
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, data) => {
        return (
          <Popover
            content={
              <DeletePopoverContent
                deleteItem={() => {
                  deleteUser(data.id)
                }}
              />
            }
          >
            <Button
              danger
              type='link'
              onClick={(e) => {
                e.stopPropagation()
              }}
              icon={<DeleteOutlined />}
            />
          </Popover>
        )
      },
    },
  ]

  return usersColumns
}
