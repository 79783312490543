import React from 'react'
import { Button, Form, Select, Space, Typography } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { apiClient, apiRequest } from '../../server/ApiClient'
import s from './unitSentTestForm.module.css'
import { AppStore } from '../../store/store'
import routesList from '../../server/routesList.json'

const UnitSentTest = ({ closeForm, unitID, packagesFormUnits }) => {
  const queryClient = useQueryClient()

  const { data: packagesList } = useQuery(['getPackages'], () =>
    apiRequest(routesList.package).get(),
  )

  const modifiedPackagesList = packagesFormUnits?.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  const { data: machinesList } = useQuery(['getMachines'], () =>
    apiRequest(routesList.agent).get(),
  )

  const modifiedMachinesList = machinesList?.items?.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  const { mutate: sentTest } = useMutation(['sendTest'], {
    mutationFn: (payload) =>
      apiRequest(`${routesList.unit}/${unitID}/sent_test`).add(payload),
    onSuccess: (data) => {
      if (data) {
        queryClient.invalidateQueries('units')
        AppStore.update((s) => {
          s.notifications.title = 'Успешно'
          s.notifications.text = 'Юнит отправлен на тест'
          s.notifications.type = 'success'
        })
        closeForm()
      }
    },
  })

  const onSubmit = (payload) => {
    sentTest(payload)
  }

  const { Title } = Typography

  return (
    <>
      <Title level={3}>Отправка на тест</Title>

      <Form
        name='test_unit_forms'
        onFinish={onSubmit}
        autoComplete='off'
        className={s.form}
      >
        <Form.List name='packages'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align='vertical'
                >
                  <Form.Item {...restField} name={[name, 'package_id']} label='Пакет'>
                    <Select
                      placeholder='Выберите пакет'
                      allowClear
                      options={modifiedPackagesList}
                    />
                  </Form.Item>

                  <Form.Item {...restField} name={[name, 'machine_id']} label='Машина'>
                    <Select
                      placeholder='Выберите машину'
                      options={modifiedMachinesList}
                      allowClear
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UnitSentTest
