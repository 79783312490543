import React, { useState } from 'react'
import { Button, Cascader, Form, Input, Radio, Select, Table } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { apiClient, apiRequest, MainAPI } from '../../server/ApiClient'
import { commandColumns } from '../../columns/commands'
import { AppStore } from '../../store/store'
import s from './addCommandForAgent.module.css'
import { AppstoreAddOutlined } from '@ant-design/icons'
import commandsConstructor from '../../constans/commandConstructor.json'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const AddCommandForm = ({ agent_id, info }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const { mutate: addCommand } = useMutation(
    ['add_command_for_agent'],

    {
      mutationFn: (payload) => apiRequest(routesList['pca/command']).add(payload),
      onSuccess: () => {
        form.resetFields()
        queryClient.invalidateQueries('getAgentCommands')

        AppStore.update((s) => {
          s.notifications.title = 'Успешно'
          s.notifications.text = 'Команда добавлена'
          s.notifications.type = 'success'
        })
      },
      onError: (error) => {
        AppStore.update((s) => {
          s.notifications.title = 'Ошибка'
          s.notifications.text = 'Команда не добавлена'
          s.notifications.type = 'error'
        })

        useSendNotifications.error(error)
      },
    },
  )

  const { data: agentCommands, isLoading } = useQuery(
    ['getAgentCommands', agent_id],
    () =>
      apiRequest(routesList['pca/command']).get(0, 200, {
        agent_id,
      }),
    {
      enabled: !!agent_id,
    },
  )

  const modifiedAgentCommand = agentCommands?.items?.map((command) => ({
    ...command,
    key: uuidv4(),
  }))

  const [currentCommand, setCurrentCommand] = useState('')

  const onSubmit = (payload) => {
    addCommand({ agent_id, command: currentCommand })
  }

  const modifiedCommandConstructor = commandsConstructor.commands.map((command) => {
    return {
      label: command.name,
      value: command.name,
      isLeaf: false,
    }
  })

  const [cascaderOptions, setCascaderOptions] = useState(modifiedCommandConstructor)

  const [id, setId] = useState({
    client_id: null,
    product_id: null,
    package_id: null,
  })

  const { client_id, product_id, package_id } = id

  useQuery(
    ['get_clients_list_for_command', currentCommand === 'install'],
    () => apiRequest(routesList.clients).get(),
    {
      enabled: currentCommand === 'install',
      onSuccess: (data) => {
        setCascaderOptions((oldValue) =>
          oldValue.map((item) => {
            return {
              ...item,
              isLeaf: false,
              children: data?.items?.map((client) => {
                return {
                  value: client.id,
                  label: client.name,
                  isLeaf: false,
                }
              }),
            }
          }),
        )
      },
    },
  )

  useQuery(
    ['products_list_for_command', client_id],
    () =>
      apiRequest(routesList.product).get(0, 100, {
        client_id,
      }),
    {
      enabled: !!client_id,
      onSuccess: (data) => {
        setCascaderOptions((oldValue) =>
          oldValue.map((item) => {
            return {
              ...item,
              isLeaf: false,
              children: item?.children?.map((client) => {
                return {
                  ...client,
                  isLeaf: false,
                  children: data?.items?.map((product) => {
                    return {
                      label: product.name,
                      value: product.id,
                      isLeaf: false,
                    }
                  }),
                }
              }),
            }
          }),
        )
      },
    },
  )

  const { data: unitsList } = useQuery(
    ['units', product_id],
    () =>
      apiRequest(routesList.unit).get(0, 100, {
        product_id,
        order_by: '-created_at',
        status: 'approved',
        limit: 1,
      }),
    {
      enabled: !!product_id,
      onSuccess: (data) => {
        setCascaderOptions((oldValue) =>
          oldValue.map((item1) => {
            return {
              ...item1,
              children: item1?.children?.map((client) => {
                return {
                  ...client,
                  children: client.children?.map((product) => {
                    return {
                      ...product,
                      children: data.items[0].packages.map((package1) => {
                        return {
                          value: package1.id,
                          label: package1.name,
                        }
                      }),
                    }
                  }),
                }
              }),
            }
          }),
        )
      },
    },
  )

  const { data: currentPackage } = useQuery(
    ['get_current_package', package_id],
    () => apiRequest(routesList.package).getItem(package_id),
    {
      enabled: !!package_id,
      onSuccess: (data) => {
        if (data) {
          console.log(data)
          setCascaderOptions((oldValue) =>
            oldValue.map((el) => {
              return {
                ...el,
                children: el.children.map((el1) => {
                  return {
                    ...el1,
                    children: el1.children.map((el2) => {
                      return {
                        ...el2,
                        children: data.packageitems.map((el3) => {
                          return {
                            label: el3.software.name,
                            value: el3.software_id,
                          }
                        }),
                      }
                    }),
                  }
                }),
              }
            }),
          )
        }
      },
    },
  )

  const onCascaderChange = (valueArr, value) => {
    switch (valueArr[0]) {
      case 'install':
        setCurrentCommand('install')

        if (valueArr.length === 2) {
          setId((oldValue) => ({ ...oldValue, client_id: valueArr[1] }))
        }

        if (valueArr.length === 3) {
          setId((oldValue) => ({ ...oldValue, product_id: valueArr[2] }))
        }

        if (valueArr.length === 4) {
          const installCommand = `${valueArr[0]} --c ${valueArr[1]} --r ${valueArr[2]} --p ${valueArr[3]}`

          setCurrentCommand(installCommand)
          form.setFieldValue('command', installCommand)
        }

        return
      case 'update':
        setCurrentCommand('update')
        setCascaderOptions((oldValue) =>
          oldValue.map((command) => {
            return {
              ...command,
              children: [
                { value: '--s', label: 'Самого себя' },
                {
                  value: '--p',
                  label: 'Конкретный пакет',
                  isLeaf: false,
                  children: info.installed_packages.map((item1) => {
                    return {
                      value: item1.id,
                      label: item1.name,
                    }
                  }),
                },
              ],
            }
          }),
        )

        setCurrentCommand(valueArr.join(' '))
        form.setFieldValue('command', valueArr.join(' '))
        return
      case 'remove':
        setCurrentCommand('remove')
        setCascaderOptions((oldValue) =>
          oldValue.map((command) => {
            return {
              ...command,
              isLeaf: false,
              children: [
                { value: '', label: 'Все пакеты', isLeaf: false },
                { value: '--p', label: 'Конкретный пакет', isLeaf: false },
              ],
            }
          }),
        )

        if (valueArr[1] === '--p') {
          setCascaderOptions((oldValue) =>
            oldValue.map((command) => {
              return {
                ...command,
                isLeaf: false,
                children: command.children.map((item) => {
                  return {
                    ...item,
                    children: info.installed_packages.map((item1) => {
                      return {
                        value: item1.id,
                        label: item1.name,
                      }
                    }),
                  }
                }),
              }
            }),
          )
        }

        setCurrentCommand(valueArr.join(' '))
        form.setFieldValue('command', valueArr.join(' '))

        return
      case 'soft':
        setCurrentCommand('soft')
        setCascaderOptions((oldValue) =>
          oldValue.map((el) => {
            return {
              ...el,
              children: [
                {
                  value: 'config',
                  label: 'Конфигурация',
                  children: info.installed_packages.map((package_item) => {
                    return {
                      value: package_item.id,
                      label: package_item.name,
                      isLeaf: false,
                    }
                  }),
                },
              ],
            }
          }),
        )

        if (valueArr.length === 3) {
          setId((oldValue) => ({ ...oldValue, package_id: valueArr[2] }))
        }

        if (valueArr.length === 4) {
          const installCommand = `${valueArr[0]} ${valueArr[1]} --s ${valueArr[3]}`

          setCurrentCommand(installCommand)
          form.setFieldValue('command', installCommand)
        }

        return
      default:
        return 'Нет данных'
    }
  }

  return (
    <>
      <Form
        name='add_command'
        layout='vertical'
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete='off'
        onFinish={onSubmit}
        style={{ display: 'flex', alignItems: 'center', columnGap: 20 }}
      >
        <Form.Item
          label='Команда'
          name='command'
          rules={[
            {
              required: true,
              message: 'Введите команду',
            },
          ]}
          style={{ flexGrow: 0.1 }}
        >
          <Input size='large' />
        </Form.Item>

        <Form.Item
          className={s.formItem}
          wrapperCol={{
            offset: 0,
            span: 24,
          }}
        >
          <Button type='primary' htmlType='submit' size='large'>
            <AppstoreAddOutlined />
            Добавить
          </Button>
        </Form.Item>
      </Form>

      <Cascader
        size='large'
        options={cascaderOptions}
        placeholder='Конструктор консольных команд'
        style={{ marginBottom: 20, width: '100%' }}
        changeOnSelect
        onChange={onCascaderChange}
      />

      <Table
        columns={commandColumns}
        loading={isLoading}
        dataSource={modifiedAgentCommand}
        pagination={false}
      />
    </>
  )
}

export default AddCommandForm
