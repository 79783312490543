import React, { useEffect } from 'react'
import { Button, Form, Input, Tooltip } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import {
  ExclamationCircleOutlined,
  PlusSquareOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { AppStore } from '../../store/store'
import { passwordRegExp } from '../../constans/regExp'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const UsersForm = ({ closeDrawer, id, info }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const { mutate: addUser } = useMutation(['add_user'], {
    mutationFn: (data) => apiRequest(routesList.user).add(data),
    onSuccess: () => {
      closeDrawer()
      queryClient.invalidateQueries('users')
      form.resetFields()

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Пользователь добавлен'
        s.notifications.type = 'success'
      })
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при добавлении пользователя'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const { mutate: updateUser } = useMutation(['update_user'], {
    mutationFn: (payload) => apiRequest(routesList.user).update({ id, payload }),
    onSuccess: () => {
      closeDrawer()
      queryClient.invalidateQueries('users')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Информация о пользователе обновлена'
        s.notifications.type = 'success'
      })
    },
    onError: () => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при обновлении информации о пользователе'
        s.notifications.type = 'error'
      })
    },
  })

  const onSubmit = (payload) => {
    if (!id) {
      addUser(payload)
      return
    }

    if (!!id) {
      updateUser(payload)
      return
    }
  }

  useEffect(() => {
    if (info) {
      const { name, surname, patronymic, login } = info

      const payload = { name, surname, patronymic, login }

      form.setFieldsValue(payload)
    }
  }, [info])

  return (
    <Form
      name='add_command'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      <Form.Item
        label='Логин'
        name='login'
        rules={[
          {
            required: true,
            message: 'Введите логин',
          },
        ]}
      >
        <Input size='large' />
      </Form.Item>

      {/*{(!info || !id) && (*/}
      {/*  <Form.Item*/}
      {/*    label={*/}
      {/*      <span>*/}
      {/*        Пароль{' '}*/}
      {/*        <Tooltip*/}
      {/*          title='Пароль обязательно должен содержать от 6 до 32 символов, цифру*/}
      {/*            и большую букву'*/}
      {/*        >*/}
      {/*          <ExclamationCircleOutlined />*/}
      {/*        </Tooltip>*/}
      {/*      </span>*/}
      {/*    }*/}
      {/*    name='password'*/}
      {/*    rules={[*/}
      {/*      {*/}
      {/*        required: true,*/}
      {/*        message: 'Введите пароль',*/}
      {/*        pattern: passwordRegExp,*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  >*/}
      {/*    <Input.Password size='large' />*/}
      {/*  </Form.Item>*/}
      {/*)}*/}

      <Form.Item
        label={
          <span>
            Пароль{' '}
            <Tooltip
              title='Пароль обязательно должен содержать от 6 до 32 символов, цифру
                  и большую букву'
            >
              <ExclamationCircleOutlined />
            </Tooltip>
          </span>
        }
        name='password'
        rules={[
          {
            required: !info || !id,
            message: 'Введите пароль',
            pattern: passwordRegExp,
          },
        ]}
      >
        <Input.Password size='large' />
      </Form.Item>

      <Form.Item label='Имя' name='name'>
        <Input size='large' />
      </Form.Item>

      <Form.Item label='Фамилия' name='surname'>
        <Input size='large' />
      </Form.Item>

      <Form.Item label='Отчество' name='patronymic'>
        <Input size='large' />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
        style={{ alignSelf: 'center', marginBottom: 0 }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          <UserAddOutlined />
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UsersForm
