import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { JsonEditor as Editor } from 'jsoneditor-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'jsoneditor-react/es/editor.min.css'
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { softKindListOptions } from '../../constans/optionsList/softKindOptions'
import { AppStore } from '../../store/store'
import { PlusSquareOutlined } from '@ant-design/icons'
import routesList from '../../server/routesList.json'

const SoftForm = ({ id, closeDrawer }) => {
  const queryClient = useQueryClient()

  const [form] = Form.useForm()

  const [checkboxValue, setCheckboxValue] = useState(false)

  const [jsonValue, setJsonValue] = useState({})

  const [isJSONEditorVisible, setIsJSONEditorVisible] = useState(false)

  const onChangeJSON = (e) => {
    setJsonValue(e)
  }

  useQuery(
    ['get_software_item_info', id],
    () => apiRequest(routesList.software).getItem(id),
    {
      enabled: !!id,
      onSuccess: (data) => {
        form.setFieldsValue(data)
        form.setFieldValue('config.path', data.config.path)
        setJsonValue(JSON.parse(data.config.raw_data))
      },
    },
  )

  const { mutate: addNewSoftware } = useMutation(['add_new_software'], {
    mutationFn: (payload) => apiRequest(routesList.software).add(payload),
    onSuccess: () => {
      queryClient.invalidateQueries('soft_list')
      form.resetFields()
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Софт добавлен'
        s.notifications.type = 'success'
      })
      closeDrawer()
    },
  })

  const { mutate: updateSoftware } = useMutation(['update_software_info'], {
    mutationFn: (payload) => apiRequest(routesList.software).update({ id, payload }),
    onSuccess: () => {
      queryClient.invalidateQueries('soft_list')
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Софт обновлен'
        s.notifications.type = 'success'
      })
    },
  })

  const onSubmit = (payload) => {
    const payloadDataWithJSON = {
      name: payload.name,
      kind: payload.kind,
      gitlab_id: payload.gitlab_id,
      url: payload.url,
      description: payload.description,
      external_key: payload.external_key,
      config: {
        path: payload['config.path'],
        raw_data: JSON.stringify(jsonValue),
      },
    }

    const payloadDataWithoutJSON = {
      name: payload.name,
      kind: payload.kind,
      gitlab_id: payload.gitlab_id,
      url: payload.url,
      description: payload.description,
      external_key: payload.external_key,
    }

    if (!id) {
      if (!!checkboxValue) {
        addNewSoftware(payloadDataWithJSON)
        console.log(payloadDataWithJSON)
        return
      }
      console.log(payloadDataWithoutJSON)
      addNewSoftware(payloadDataWithoutJSON)

      return
    }

    if (id) {
      console.log(payloadDataWithJSON)
      updateSoftware(payloadDataWithJSON)
      return
    }
  }

  return (
    <Form
      name='soft'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      {/*<Modal*/}
      {/*  title='Редактор JSON'*/}
      {/*  open={isJSONEditorVisible}*/}
      {/*  onOk={() => {*/}
      {/*    setIsJSONEditorVisible(false)*/}
      {/*  }}*/}
      {/*  onCancel={() => {*/}
      {/*    setIsJSONEditorVisible(false)*/}
      {/*  }}*/}
      {/*  width={800}*/}
      {/*  style={{ height: '100%' }}*/}
      {/*>*/}
      {/*  <Editor value={jsonValue} onChange={onChangeJSON} mode='text' />*/}
      {/*</Modal>*/}

      <Form.Item
        label='Название'
        name='name'
        rules={[
          {
            required: true,
            message: 'Введите название',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Вид софта'
        name='kind'
        rules={[
          {
            required: true,
            message: 'Выберите вид софта',
          },
        ]}
      >
        <Select
          placeholder='Выберите вид софта'
          allowClear
          options={softKindListOptions}
        />
      </Form.Item>

      {!id && (
        <Form.Item
          label='Gitlab_id'
          name='gitlab_id'
          rules={[
            {
              required: true,
              message: 'Введите gitlab_id',
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item label='Ссылка на репозиторий' name='url'>
        <Input />
      </Form.Item>

      <Form.Item label='Описание' name='description'>
        <Input />
      </Form.Item>

      <Form.Item label='Уникальный ключ' name='external_key'>
        <Input />
      </Form.Item>

      <Checkbox
        style={{ marginBottom: 20 }}
        onChange={(e) => {
          setCheckboxValue(e.target.checked)
        }}
      >
        Добавить конфигурацию
      </Checkbox>

      {checkboxValue && (
        <>
          <Form.Item label='Путь до файла конфигурации' name='config.path'>
            <Input />
          </Form.Item>

          <Editor value={jsonValue} onChange={onChangeJSON} mode='text' />
        </>
      )}

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
        style={{ marginTop: 20 }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          <PlusSquareOutlined />
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SoftForm
