import React from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest, MainAPI } from '../../server/ApiClient'
import { AppStore } from '../../store/store'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const ProductForm = ({ id, closeDrawer }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const { mutate: addProduct } = useMutation(['add_product'], {
    mutationFn: (payload) => apiRequest(routesList.product).add(payload),
    onSuccess: () => {
      queryClient.invalidateQueries('product_list')
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Продукт добавлен'
        s.notifications.type = 'success'
      })
      form.resetFields()
      closeDrawer()
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при добавлении продукта'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const { data: clientsList } = useQuery(['clients_list_select'], () =>
    apiRequest(routesList.clients).get(),
  )

  const modifiedClientsList = clientsList?.items?.map((el) => ({
    value: el.id,
    label: el.name,
  }))

  useQuery(['get_pr_item_info', id], () => apiRequest(routesList.product).getItem(id), {
    enabled: !!id,
    onSuccess: (data) => {
      form.setFieldsValue(data)
    },
  })

  const { mutate: updateProduct } = useMutation(['update_pr_info'], {
    mutationFn: (payload) => apiRequest(routesList.product).update({ id, payload }),
    onSuccess: () => {
      queryClient.invalidateQueries('product_list')
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Продукт обновлен'
        s.notifications.type = 'success'
      })
      closeDrawer()
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при редактировании продукта'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const onSubmit = (payload) => {
    if (!id) {
      addProduct(payload)
      return
    }

    if (id) {
      updateProduct(payload)
      return
    }
  }

  return (
    <Form
      name='add_project'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      <Form.Item
        label='Название продукта'
        name='name'
        rules={[
          {
            required: true,
            message: 'Введите название',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Описание продукта'
        name='description'
        rules={[
          {
            required: true,
            message: 'Введите описание продукта',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Клиент'
        name='client_id'
        rules={[
          {
            required: true,
            message: 'Выберите клиента',
          },
        ]}
      >
        <Select placeholder='Выберите клиента' allowClear options={modifiedClientsList} />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ProductForm
