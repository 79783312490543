import React from 'react'
import { Card, List, Typography } from 'antd'
import s from '../../pages/units/units.module.css'
import Meta from 'antd/es/card/Meta'
import { useQuery } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'
import { InboxOutlined } from '@ant-design/icons'

const ProductsOnUnitsPage = ({
  client_id,
  setId,
  setIsVisible,
  product_id,
  products,
  setPackagesFromUnits,
}) => {
  const { Title } = Typography

  const { data: productsList, isSuccess: isProductSuccess } = useQuery(
    ['products', client_id],
    () => apiRequest(routesList.product).get(0, 100, { client_id, order_by: '-id' }),
    {
      enabled: !!client_id,
    },
  )

  const productItems = productsList?.items?.map((el) => {
    return (
      <List.Item key={el.id} style={{ height: '100%' }}>
        <Card
          className={s.cardInfo}
          onClick={() => {
            setId((oldValue) => ({ ...oldValue, product_id: el.id }))
            setIsVisible((oldValue) => ({ ...oldValue, units: true, packages: false }))
            setPackagesFromUnits([])
          }}
          style={{ backgroundColor: product_id === el.id ? '#c1c5cb' : '' }}
        >
          <Meta title={el.name} description={el.description} />
        </Card>
      </List.Item>
    )
  })

  return (
    <>
      {products && (
        <div className='units__box'>
          <Title style={{ color: '#505962' }} level={2}>
            <InboxOutlined /> Продукты
          </Title>

          <List
            grid={{ gutter: [4, 16], xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 4 }}
            dataSource={productItems}
            renderItem={(item) => item}
          />
        </div>
      )}
    </>
  )
}

export default ProductsOnUnitsPage
