import React from 'react'
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router'
import { getToken } from '../../utils/authorization'
import { routesPath } from '../../routing/path'

const PublicRoute = ({ children }) => {
  const location = useLocation()

  if (getToken()) {
    return <Navigate to={`${routesPath.clients}`} state={{ from: location }} />
  }
  return <>{children}</>
}

export default PublicRoute
