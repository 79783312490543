import React from 'react'
import { useQuery } from 'react-query'
import { apiClient } from '../../server/ApiClient'
import TableComponent from '../../components/tableComponent/tableComponent'
import { deployJobTabs } from '../../constans/tabs/deployJob'
import { deployJobColumns } from '../../columns/deployJob'
import { AppStore } from '../../store/store'
import DeployJobInfo from '../../components/deployJobInfo'

const DeployJob = () => {
  const offset = AppStore.useState((s) => s.table.offset)

  const limit = AppStore.useState((s) => s.table.limit)

  const { data: deployJobData, isLoading } = useQuery(
    ['deployJobList', offset, limit],
    () => apiClient.getDeployJobList(offset, limit),
  )

  const modifiedDeployJobList = deployJobData?.items?.map((el) => ({ ...el, key: el.id }))

  return (
    <TableComponent
      title='Задачи развертывания'
      data={modifiedDeployJobList}
      total={deployJobData?.total}
      columns={deployJobColumns}
      isLoading={isLoading}
      tabContent={(id, rowData) => {
        return <DeployJobInfo info={rowData} />
      }}
      isAddButtonHidden
      tabs={deployJobTabs}
      limit={limit}
    />
  )
}

export default DeployJob
