import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Empty,
  Popover,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from 'antd'
import {
  ApartmentOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons'
import s from './freePackages.module.css'
import Search from 'antd/es/input/Search'
import { CSSTransition } from 'react-transition-group'
import CustomPagination from '../../components/pagination'
import { AppStore } from '../../store/store'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import DeletePopoverContent from '../../components/deletePopoverContent'
import { v4 as uuidv4 } from 'uuid'
import PackageForm from '../../components/packageForm/PackageForm'
import PackageHistoryInfo from '../../components/PackageHistoryInfo/PackageHistoryInfo'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const FreePackages = () => {
  const queryClient = useQueryClient()

  const [searchInputValue, setSearchInputValue] = useState('')

  const offset = AppStore.useState((s) => s.pagination.offset)

  const limit = AppStore.useState((s) => s.pagination.limit)

  const {
    data: packagesList,
    isLoading,
    isSuccess,
  } = useQuery(['packages_list', offset, limit, searchInputValue], () =>
    apiRequest(`${routesList.package}/free`).get(offset, limit, {
      name__icontains: searchInputValue,
    }),
  )

  const { mutate: deleteItem } = useMutation(['delete_package'], {
    mutationFn: (id) => apiRequest(routesList.package).delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries('packages_list')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Пакет удален'
        s.notifications.type = 'success'
      })
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при удалении пакета'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const { Title } = Typography

  const [drawerState, setDrawerState] = useState('')

  const [open, setOpen] = useState(false)

  const [itemId, setItemId] = useState(null)
  const [cardData, setCardData] = useState(null)

  const showDrawer = () => {
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  const onAddButtonHandle = (e) => {
    e.preventDefault()
    setDrawerState('add')
    setOpen(true)
  }

  const getActions = (id, data) => [
    <Tooltip key='edit' title='Редактирование'>
      <Button
        type='link'
        icon={<EditOutlined />}
        onClick={() => {
          setItemId(id)
          setCardData(data)
          setDrawerState('edit')
          showDrawer()
        }}
      />
    </Tooltip>,
    <Tooltip key='info' title='Информация'>
      <Button
        type='link'
        icon={<ExclamationCircleOutlined />}
        onClick={() => {
          setItemId(id)
          setDrawerState('info')
          showDrawer()
        }}
      />
    </Tooltip>,
    <Tooltip key='inherit' title='Наследование'>
      <Button
        type='link'
        icon={<ApartmentOutlined />}
        onClick={() => {
          setItemId(id)
          setCardData(data)

          setDrawerState('inherit')
          AppStore.update((s) => {
            s.units.legacyMode = true
          })
          showDrawer()
        }}
      />
    </Tooltip>,

    <Popover
      key='delete'
      content={<DeletePopoverContent deleteItem={() => deleteItem(id)} />}
    >
      <Button danger type='link' icon={<DeleteOutlined />} />
    </Popover>,
  ]

  const getPackagesCards = () => {
    if (isLoading) return <Skeleton active />
    if (!isSuccess)
      return (
        <Col offset={10}>
          <Empty />
        </Col>
      )

    return packagesList?.items?.map((packageItem) => (
      <Col xxl={4} xl={6} lg={12} md={12} key={uuidv4()}>
        <Card
          title={packageItem.name}
          actions={getActions(packageItem.id, packageItem)}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <>
            <Descriptions column={1}>
              <Descriptions.Item className={s.description_text} label='Описание'>
                <Tooltip placement='left' title={packageItem?.description}>
                  {packageItem?.description}
                </Tooltip>
              </Descriptions.Item>

              <Descriptions.Item label='Количество софта'>
                {packageItem?.packageitems?.length}
              </Descriptions.Item>

              <Descriptions.Item label='Внутренний id'>
                {packageItem?.inner_id ? packageItem?.inner_id : 'Отсутствует'}
              </Descriptions.Item>
            </Descriptions>
          </>
        </Card>
      </Col>
    ))
  }

  const nodeRef = useRef(null)

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    if (packagesList?.total <= 0) {
      setInProp(true)
      return
    }

    if (packagesList?.total > 0) {
      setInProp(false)
      return
    }
  }, [inProp, packagesList])

  const getDrawerContent = (drawerState) => {
    switch (drawerState) {
      case 'add':
        return <PackageForm closeDrawer={closeDrawer} />
      case 'edit':
        return (
          <PackageForm id={itemId} packageData={cardData} closeDrawer={closeDrawer} />
        )
      case 'info':
        return <PackageHistoryInfo packageInfoId={itemId} />
      case 'inherit':
        return <PackageForm id={itemId} packageData={cardData} />
      default:
        return 'Нет контента'
    }
  }

  return (
    <div className={s.freePackages}>
      <Row>
        <Col span={24}>
          <Title level={1}>Неприсвоенные пакеты</Title>
        </Col>
      </Row>

      <Row style={{ marginBottom: '20px' }} gutter={24}>
        <Col xxl={{ span: 2, offset: 18 }}>
          <Button type='primary' onClick={onAddButtonHandle} size='large'>
            <PlusSquareOutlined />
            Добавить
          </Button>
        </Col>

        <Col xxl={{ span: 4 }}>
          <Search
            placeholder='Поиск продукта'
            allowClear
            size='large'
            onSearch={(value) => {
              setSearchInputValue(value)
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                setSearchInputValue('')
              }
            }}
          />
        </Col>
      </Row>

      <Row gutter={[4, 4]} style={{ marginBottom: 20 }}>
        {getPackagesCards()}

        <CSSTransition
          nodeRef={nodeRef}
          in={inProp}
          timeout={300}
          unmountOnExit
          classNames='empty'
        >
          <>
            {packagesList?.total <= 0 ? (
              <Col offset={10} ref={nodeRef}>
                <Empty />
              </Col>
            ) : null}
          </>
        </CSSTransition>
      </Row>

      {packagesList?.total > 0 && (
        <Row>
          <Col>
            <CustomPagination total={packagesList?.total} limit={limit} />
          </Col>
        </Row>
      )}

      <Drawer
        title='Пакеты'
        placement='right'
        closable={true}
        onClose={() => {
          closeDrawer()
          AppStore.update((s) => {
            s.units.legacyMode = false
          })
          setDrawerState('')
        }}
        open={open}
        getContainer={false}
        style={{ backgroundColor: 'lightgray' }}
        size='large'
      >
        {getDrawerContent(drawerState)}
      </Drawer>

      <div className='dividerBox' style={{ marginBottom: 50 }}>
        <Divider orientation='left'>Описание</Divider>
        <p>Добавленные пакеты, но не прикрепленные к определенному юниту</p>
      </div>
    </div>
  )
}

export default FreePackages
