import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import ProductForm from '../../components/productForm/ProductForm'
import ProductInfo from '../../components/productInfo/ProductInfo'
import s from './products.module.css'
import { AppStore } from '../../store/store'
import { CSSTransition } from 'react-transition-group'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Empty,
  Modal,
  Popover,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons'
import CustomPagination from '../../components/pagination'
import Search from 'antd/es/input/Search'
import DeletePopoverContent from '../../components/deletePopoverContent'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const Products = () => {
  const queryClient = useQueryClient()

  const [searchInputValue, setSearchInputValue] = useState('')

  const offset = AppStore.useState((s) => s.pagination.offset)

  const limit = AppStore.useState((s) => s.pagination.limit)

  const {
    data: productList,
    isLoading,
    isSuccess,
    isError,
    status,
  } = useQuery(['product_list', offset, limit, searchInputValue], () =>
    apiRequest(routesList.product).get(offset, limit, {
      name__icontains: searchInputValue,
      order_by: '-id',
    }),
  )

  const [open, setOpen] = useState(false)

  const { mutate: deleteProduct } = useMutation(['delete_project'], {
    mutationFn: (id) => apiRequest(routesList.product).delete(id),
    onSuccess: () => {
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Продукт удален'
        s.notifications.type = 'success'
      })

      queryClient.invalidateQueries('product_list')
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при удалении продукта'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const { Title } = Typography

  const [drawerState, setDrawerState] = useState('')
  const [itemId, setItemId] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    deleteProduct(itemId)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  const getDrawerContent = (drawerState) => {
    switch (drawerState) {
      case 'add':
        return <ProductForm closeDrawer={closeDrawer} />
      case 'edit':
        return <ProductForm closeDrawer={closeDrawer} id={itemId} />
      case 'info':
        return <ProductInfo id={itemId} />
      default:
        return 'Нет контента'
    }
  }

  const onAddButtonHandle = (e) => {
    e.preventDefault()
    setDrawerState('add')
    setOpen(true)
  }

  const getActions = (id) => [
    <Tooltip key='edit' title='Редактирование'>
      <Button
        key='edit'
        type='link'
        icon={<EditOutlined />}
        onClick={() => {
          setItemId(id)
          setDrawerState('edit')
          showDrawer()
        }}
      />
    </Tooltip>,
    <Popover
      key='delete'
      content={<DeletePopoverContent deleteItem={() => deleteProduct(id)} />}
    >
      <Button danger key='deleted' type='link' icon={<DeleteOutlined />} />
    </Popover>,
  ]

  const getProductCards = () => {
    if (isLoading) return <Skeleton />
    if (!isSuccess || !!isError)
      return (
        <Col offset={10}>
          <Empty />
        </Col>
      )

    return productList?.items?.map((product) => (
      <Col xxl={4} xl={6} lg={12} md={12} key={uuidv4()}>
        <Card
          title={product.name}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          actions={getActions(product.id)}
        >
          <>
            <Descriptions column={1}>
              <Descriptions.Item label='Описание' className={s.description_text}>
                <Tooltip placement='left' title={product?.description}>
                  {product?.description}
                </Tooltip>
              </Descriptions.Item>

              <Descriptions.Item label='Клиент'>{product.client.name}</Descriptions.Item>
            </Descriptions>
          </>
        </Card>
      </Col>
    ))
  }

  const nodeRef = useRef(null)

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    if (productList?.total <= 0) {
      setInProp(true)
      return
    }

    if (productList?.total > 0) {
      setInProp(false)
      return
    }
  }, [inProp, productList])

  return (
    <div className={s.products}>
      <Row>
        <Col span={24}>
          <Title level={1}>Продукты</Title>
        </Col>
      </Row>

      <Row style={{ marginBottom: '20px' }} gutter={[16, 16]}>
        <Col xxl={{ span: 2, offset: 18 }}>
          <Button size='large' type='primary' onClick={onAddButtonHandle}>
            <PlusSquareOutlined />
            Добавить
          </Button>
        </Col>

        <Col xxl={{ span: 4 }}>
          <Search
            placeholder='Поиск продукта'
            allowClear
            size='large'
            onSearch={(value) => {
              setSearchInputValue(value)
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                setSearchInputValue('')
              }
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        {getProductCards()}

        <CSSTransition
          nodeRef={nodeRef}
          in={inProp}
          timeout={300}
          unmountOnExit
          classNames='empty'
        >
          <>
            {productList?.total <= 0 ? (
              <Col offset={10} ref={nodeRef}>
                <Empty />
              </Col>
            ) : null}
          </>
        </CSSTransition>
      </Row>

      {productList?.total > 0 && (
        <Row>
          <Col>
            <CustomPagination total={productList?.total} limit={limit} />
          </Col>
        </Row>
      )}

      <Modal title='Удаление' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Удалить ? </p>
      </Modal>

      <Drawer
        title='Продукты'
        placement='right'
        closable={true}
        onClose={() => {
          closeDrawer()
          setDrawerState('')
        }}
        open={open}
        getContainer={false}
        style={{ backgroundColor: 'lightgray' }}
        size='large'
      >
        {getDrawerContent(drawerState)}
      </Drawer>

      <div className='dividerBox'>
        <Divider orientation='left'>Описание</Divider>
        <p>
          Набор программного обеспечения, поставляемого клиенту. Может состоять из
          нескольких модулей
        </p>
        <p> Пример: ПО Терминала + ПО очереди + ПО СОК</p>
      </div>
    </div>
  )
}

export default Products
