import { ConfigProvider, Menu, Layout, notification, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'
import ruRU from 'antd/locale/ru_RU'

import { AppStore } from './store/store'

import Routing from './routing/routing'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
})
function App() {
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type, title, text) => {
    api[type]({
      message: title,
      description: text,
    })
  }

  const notificationTitle = AppStore.useState((s) => s.notifications.title)
  const notificationText = AppStore.useState((s) => s.notifications.text)
  const notificationType = AppStore.useState((s) => s.notifications.type)

  useEffect(() => {
    if (!!notificationTitle && !!notificationText && !!notificationType) {
      openNotificationWithIcon(notificationType, notificationTitle, notificationText)

      AppStore.update((s) => {
        s.notifications.title = ''
        s.notifications.text = ''
        s.notifications.type = ''
      })
    }
  }, [notificationText, notificationTitle, notificationType, openNotificationWithIcon])

  return (
    <QueryClientProvider client={queryClient}>
      {contextHolder}
      <ConfigProvider
        locale={ruRU}
        theme={{
          token: {
            colorPrimary: 'gray',
          },
        }}
      >
        <Routing />
      </ConfigProvider>
    </QueryClientProvider>
  )
}

export default App
