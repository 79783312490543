import React, { useState } from 'react'
import s from './editableInfo.module.css'
import { Button, Input, Space } from 'antd'

const EditableInfo = ({
  placeholder,
  value,
  onChange,
  name,
  onClickHandle,
  isLoading,
}) => {
  const [inputDisabled, setInputDisabled] = useState(true)

  return (
    <Space.Compact size='large'>
      <Input
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={inputDisabled}
        size='large'
      />
      <Button
        onClick={() => {
          setInputDisabled((oldValue) => !oldValue)

          if (!inputDisabled && onClickHandle) {
            onClickHandle()
          }
        }}
      >
        {inputDisabled ? 'Редактировать' : 'Сохранить'}
      </Button>
    </Space.Compact>
  )
}

export default EditableInfo
