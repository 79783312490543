import React from 'react'
import { useQuery } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import InfoItem from '../infoItem/InfoItem'
import { Descriptions, Typography } from 'antd'
import routesList from '../../server/routesList.json'

const SoftInfo = ({ id, info }) => {
  const { data: softwareItem } = useQuery(
    ['get_current_software', id],
    () => apiRequest(routesList.software).getItem(id),
    {
      enabled: !!id,
    },
  )

  const softInfo = info ? info : softwareItem

  return (
    <Descriptions column={1}>
      <Descriptions.Item label='Название'>{softInfo?.name}</Descriptions.Item>
      <Descriptions.Item label='Версия'>{softInfo?.version}</Descriptions.Item>
      <Descriptions.Item label='Описание'>{softInfo?.description}</Descriptions.Item>
      <Descriptions.Item label='Вид'>{softInfo?.kind}</Descriptions.Item>
      <Descriptions.Item label='Ссылка на gitlab'>{softInfo?.url}</Descriptions.Item>
      <Descriptions.Item label='gitlab_id'>{softInfo?.gitlab_id}</Descriptions.Item>
    </Descriptions>
  )
}

export default SoftInfo
