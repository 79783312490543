import React, { useState, useRef } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient } from '../../server/ApiClient'
import CardsList from '../../components/cardsList/CardsList'
import ProjectForm from '../../components/projectForm/ProjectForm'
import ProjectInfo from '../../components/projectInfo/ProjectInfo'
import { AppStore } from '../../store/store'

function Projects() {
  const queryClient = useQueryClient()

  const offset = AppStore.useState((s) => s.pagination.offset)

  const limit = AppStore.useState((s) => s.pagination.limit)

  const { data: projectsList } = useQuery(['project_list', offset, limit], () =>
    apiClient.getProjectList(offset, limit),
  )

  const { mutate: deleteItem } = useMutation(
    ['delete_project'],
    apiClient.deleteProjectItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('project_list')
      },
    },
  )

  return (
    <CardsList
      title='Проекты'
      data={projectsList?.items}
      dataLength={projectsList?.total}
      drawerTitle='Проекты'
      deleteItem={deleteItem}
      getAddForm={() => <ProjectForm />}
      getEditForm={(id) => <ProjectForm id={id} />}
      getInfo={(id) => <ProjectInfo id={id} />}
    />
  )
}

export default Projects
