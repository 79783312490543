import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import ClientForm from '../../components/clientForm/ClientForm'
import s from './clients.module.css'
import routesList from '../../server/routesList.json'
import { AppStore } from '../../store/store'
import {
  Skeleton,
  Card,
  Row,
  Tooltip,
  Button,
  Drawer,
  Input,
  Typography,
  Col,
  Pagination,
  Divider,
  Empty,
  Popover,
} from 'antd'
import { DeleteOutlined, PlusSquareOutlined, EditOutlined } from '@ant-design/icons'
import DeletePopoverContent from '../../components/deletePopoverContent'

const { Search } = Input
const { Title } = Typography
const { Meta } = Card

const Clients = () => {
  const [clintDrawer, setClintDrawer] = useState({
    isOpen: false,
    data: {},
    type: {},
  })
  const [currentPage, setCurrentPage] = useState(1)
  const queryClient = useQueryClient()

  const [searchInputValue, setSearchInputValue] = useState('')

  const offset = AppStore.useState((s) => s.pagination.offset)

  const limit = AppStore.useState((s) => s.pagination.limit)

  const {
    data: clientsList,
    isLoading,
    isSuccess,
  } = useQuery(['clients_list', offset, limit, searchInputValue], () =>
    apiRequest(routesList.clients).get(offset, limit, {
      name__icontains: searchInputValue,
      order_by: '-id',
    }),
  )

  const { mutate: deleteClient } = useMutation(['delete_client'], {
    mutationFn: (id) => apiRequest(routesList.clients).delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries('clients_list')
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Клиент удален'
        s.notifications.type = 'success'
      })
    },
  })

  const paginationChange = (page) => {
    setCurrentPage(page)
    AppStore.update((s) => {
      s.pagination.offset = (page - 1) * limit
    })
  }

  const onAdd = () => {
    setClintDrawer({
      isOpen: true,
      type: 'add',
    })
  }

  const onEdit = (client) => {
    setClintDrawer({
      isOpen: true,
      data: client,
      type: 'eddit',
    })
  }
  const resetDrawer = () => {
    setClintDrawer({
      isOpen: false,
      data: {},
      type: {},
    })
  }

  const renderContent = () => {
    if (isLoading) return <Skeleton active />
    if (!isSuccess || clientsList.total <= 0) return <Empty />

    return (
      <Row style={{ marginBottom: 15 }} gutter={[16, 16]}>
        {clientsList?.items?.map((client) => (
          <Col xxl={4} xl={6} lg={12} md={12} key={uuidv4()}>
            <Card
              hoverable
              style={{ width: 240, height: '100%' }}
              cover={
                <img
                  alt='example'
                  style={{ minHeight: '300px', objectFit: 'contain', padding: 10 }}
                  src={
                    client.avatar
                      ? client.avatar
                      : 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
                  }
                />
              }
              actions={[
                <Popover
                  key='del'
                  content={
                    <DeletePopoverContent deleteItem={() => deleteClient(client.id)} />
                  }
                >
                  <Button danger key='deleted' type='link' icon={<DeleteOutlined />} />
                </Popover>,
                <Tooltip title='Редактировать' key='eddit'>
                  <Button
                    key='deleted'
                    type='link'
                    icon={<EditOutlined />}
                    onClick={() => {
                      onEdit(client)
                    }}
                  />
                </Tooltip>,
              ]}
            >
              <Meta
                title={client.name}
                description={
                  client.description ? client.description : 'Описание отсутствует'
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <div className={s.page}>
      <Row>
        <Col span={24}>
          <Title level={1}>Клиенты</Title>
        </Col>
      </Row>

      <Row style={{ marginBottom: '20px' }} gutter={[16, 16]}>
        <Col xxl={{ span: 2, offset: 18 }}>
          <Button size='large' type='primary' onClick={onAdd}>
            <PlusSquareOutlined />
            Добавить
          </Button>
        </Col>

        <Col xxl={{ span: 4 }}>
          <Search
            placeholder='Поиск продукта'
            allowClear
            size='large'
            onSearch={(value) => {
              setSearchInputValue(value)
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                setSearchInputValue('')
              }
            }}
          />
        </Col>
      </Row>

      <div className='page__content'>{renderContent()}</div>

      {clientsList?.items?.length > 0 && (
        <Row>
          <Col span={24}>
            <Pagination
              current={currentPage}
              total={clientsList.total}
              onChange={paginationChange}
              pageSize={limit}
              showSizeChanger={false}
            />
          </Col>
        </Row>
      )}

      <Drawer
        width={840}
        title={
          clintDrawer.type === 'add' ? 'Добавление клиента' : 'Редактрирование клиента'
        }
        placement='right'
        onClose={resetDrawer}
        open={clintDrawer.isOpen}
      >
        {clintDrawer.type === 'add' ? (
          <ClientForm closeDrawer={resetDrawer} />
        ) : (
          <ClientForm id={clintDrawer.data.id} closeDrawer={resetDrawer} />
        )}
      </Drawer>

      <div className='dividerBox'>
        <Divider orientation='left'>Описание</Divider>
        <p>Список клиентов, для которых делается Софт</p>
      </div>
    </div>
  )
}

export default Clients
