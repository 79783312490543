import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { routesPath } from './path'
import Login from '../pages/login'
import PublicRoute from '../components/publicRoute'
import { Navigate } from 'react-router'
import PrivateRoute from '../components/privateRoute'
import Clients from '../pages/clients'
import Wrapper from '../components/wrapper'
import Soft from '../pages/soft'
import FreePackages from '../pages/freePackages'
import Units from '../pages/units'
import Products from '../pages/products'
import PackageHistory from '../pages/packageHistory'
import DeployJob from '../pages/deployJob'
import Agent from '../pages/agent'
import Commands from '../pages/commands'
import Projects from '../pages/projects/Projects'
import Users from '../pages/users'
import NotFoundPage from '../pages/notFoundPage'
import License from '../pages/license'

const Routing = () => {
  return (
    <Routes>
      <Route
        path={routesPath.login}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route path='/' element={<Navigate to={`${routesPath.login}`} />} />

      <Route path='/' element={<Wrapper />}>
        <Route
          path={`${routesPath.clients}`}
          element={
            <PrivateRoute>
              <Clients />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath.soft}`}
          element={
            <PrivateRoute>
              <Soft />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath['free-packages']}`}
          element={
            <PrivateRoute>
              <FreePackages />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath.units}`}
          element={
            <PrivateRoute>
              <Units />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath.products}`}
          element={
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath['packages-history']}`}
          element={
            <PrivateRoute>
              <PackageHistory />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath['deploy-job']}`}
          element={
            <PrivateRoute>
              <DeployJob />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath.license}`}
          element={
            <PrivateRoute>
              <License />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath.agent}`}
          element={
            <PrivateRoute>
              <Agent />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath.commands}`}
          element={
            <PrivateRoute>
              <Commands />
            </PrivateRoute>
          }
        />

        <Route
          path={`${routesPath.project}`}
          element={
            <PrivateRoute>
              <Projects />
            </PrivateRoute>
          }
        />

        <Route
          path={routesPath.users}
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />

        <Route
          path='*'
          element={
            <PrivateRoute>
              <NotFoundPage />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  )
}

export default Routing
