import { Store } from 'pullstate'

export const AppStore = new Store({
  pagination: {
    limit: 24,
    offset: 0,
  },
  table: {
    limit: 12,
    offset: 0,
  },
  units: {
    legacyMode: false,
    prev_unit_id: null,
    finalUnits: [],
  },
  notifications: {
    title: '',
    text: '',
    type: '',
  },
  user: {},
})
