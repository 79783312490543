import React, { useEffect, useState } from 'react'
import { Button, Cascader, Form, Input, Spin, Typography } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient, apiRequest } from '../../server/ApiClient'
import { AppStore } from '../../store/store'
import { Reorder } from 'framer-motion'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'

const PackageForm = ({ id, packageData, closeDrawer }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const [softwareList, setSoftwareList] = useState([])

  const modifiedSoftwareList = softwareList?.map((software) => software.value)

  const { mutate: addPackage } = useMutation(['add_package'], {
    mutationFn: (payload) => apiRequest(routesList.package).add(payload),
    onSuccess: () => {
      closeDrawer()
      queryClient.invalidateQueries('packages_list')
      form.resetFields()
      setSoftwareList([])

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Пакет добавлен'
        s.notifications.type = 'success'
      })
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при добавлении пакета'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const { mutate: updatePackage } = useMutation(['update_package'], {
    mutationFn: (payload) => apiRequest(routesList.package).update({ id, payload }),
    onSuccess: () => {
      closeDrawer()
      queryClient.invalidateQueries('packages_list')

      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Пакет обновлен'
        s.notifications.type = 'success'
      })
    },
    onError: (error) => {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Ошибка при обновлении пакета'
        s.notifications.type = 'error'
      })

      useSendNotifications.error(error)
    },
  })

  const { mutate: inheritPackage } = useMutation(
    ['inherit_package'],
    // apiClient.packageInherit,
    {
      mutationFn: (payload) =>
        apiRequest(`${routesList.package}/${id}/inherit`).add(payload),
      onSuccess: () => {
        queryClient.invalidateQueries('packages_list')
        setSoftwareList([])

        AppStore.update((s) => {
          s.notifications.title = 'Успешно'
          s.notifications.text = 'Пакет отнаследован'
          s.notifications.type = 'success'
        })
      },
      onError: (error) => {
        AppStore.update((s) => {
          s.notifications.title = 'Ошибка'
          s.notifications.text = 'Ошибка при наследовании пакета'
          s.notifications.type = 'error'
        })

        useSendNotifications.error(error)
      },
    },
  )

  const legacyMode = AppStore.useState((s) => s.units.legacyMode)

  const onSubmit = (payload) => {
    const modifirdSoftwareList = softwareList?.map((el, index) => ({
      software_id: el.value,
      order: index,
    }))

    if (!legacyMode && !id) {
      addPackage({
        ...payload,
        software: modifirdSoftwareList,
      })
      return
    }

    if (!!legacyMode && !!id) {
      inheritPackage({ software: modifirdSoftwareList })

      return
    }

    if (!legacyMode && !!id) {
      updatePackage({ ...payload, software: modifirdSoftwareList })
    }
  }

  useEffect(() => {
    if (packageData) {
      form.setFieldValue('description', packageData.description)

      const packageItems = packageData?.packageitems?.map((el) => {
        return {
          label: `${el.software.name} ${el.software.branch} ${el.software.version}`,
          value: el.software_id,
        }
      })

      if (packageItems && packageItems.length > 0) {
        setSoftwareList(packageItems)
      }
    }
  }, [packageData])

  const [options, setOptions] = useState([])
  const [gitLabId, setGitLabId] = useState(null)
  const [branchName, setBranchName] = useState(null)

  useQuery(['soft_list'], () => apiRequest(routesList.software).get(), {
    onSuccess: (data) => {
      if (data) {
        setOptions(
          data?.items?.map((soft) => {
            return {
              label: soft.name,
              value: soft.gitlab_id,
              isLeaf: false,
            }
          }),
        )
      }
    },
  })

  useQuery(
    ['get_branches_list', gitLabId],
    () => apiRequest(`${routesList.software}/${gitLabId}/branches`).get(),
    {
      enabled: !!gitLabId,
      onSuccess: (data) => {
        if (data) {
          setOptions((oldValue) =>
            oldValue?.map((item) => {
              return {
                ...item,
                children: data?.items.map((item1) => {
                  return {
                    label: item1.branch,
                    value: item1.branch,
                    children: [
                      {
                        value: 'zhejiang',
                        label: <Spin />,
                      },
                    ],
                  }
                }),
              }
            }),
          )
        }
      },
    },
  )

  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

  console.log(!!gitLabId && !!branchName)

  useQuery(
    ['get_versions_list', gitLabId, branchName],
    () => apiRequest(`/software/${gitLabId}/branches/${branchName}/versions`).get(),
    {
      enabled: !!gitLabId && !!branchName,
      onSuccess: (data) => {
        setOptions((oldValue) =>
          oldValue?.map((el) => {
            return {
              ...el,
              children: el.children?.map((el1) => {
                return {
                  ...el1,
                  children: data?.items
                    ?.map((el2) => {
                      return {
                        label: el2.version,
                        value: JSON.stringify(el2),
                        isLeaf: false,
                        children: el2.patches?.map((el3) => {
                          return {
                            label: el3.id,
                            value: el3.id,
                            disabled: modifiedSoftwareList?.includes(el3.id),
                          }
                        }),
                      }
                    })
                    .sort((a, b) => collator.compare(b.label, a.label)),
                }
              }),
            }
          }),
        )
      },
    },
  )

  const onChange = (valueArr, value) => {
    if (valueArr?.length === 1) {
      setGitLabId(valueArr[0])
      setBranchName(null)
      return
    }

    if (valueArr?.length >= 2) {
      setBranchName(valueArr[1])
    }

    let index = 0

    if (valueArr?.length === 4) {
      setSoftwareList((oldValue) => [
        ...oldValue,
        {
          value: valueArr[3],
          label: `${value[0].label}-${value[1].label}-${value[2].label}-${value[3].label}`,
          order: index,
        },
      ])
    }

    index++
  }

  const { Title } = Typography

  return (
    <Form
      name='add_project'
      layout='vertical'
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      autoComplete='off'
      onFinish={onSubmit}
    >
      {!id && (
        <Form.Item
          label='Название'
          name='name'
          rules={[
            {
              required: true,
              message: 'Введите',
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      {!legacyMode && (
        <Form.Item
          label='Описание'
          name='description'
          rules={[
            {
              required: true,
              message: 'Введите описание',
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Title level={5}>Список софта</Title>

      <Form.Item>
        <Cascader
          options={options}
          value={null}
          placeholder='Выберите софт'
          onChange={onChange}
          changeOnSelect
          style={{ width: '100%' }}
        />
      </Form.Item>

      {softwareList?.length > 0 && <Title level={5}>Порядок установки софта:</Title>}

      {softwareList?.length > 0 && (
        <Reorder.Group
          onReorder={(values) => {
            setSoftwareList(values)
          }}
          values={softwareList}
          axis='y'
          style={{
            marginBottom: 20,
            display: 'flex',
            rowGap: 5,
            flexDirection: 'column',
          }}
        >
          {softwareList?.map((el, index) => {
            el.order = index
            return (
              <div
                key={el.value}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  columnGap: 15,
                  maxWidth: 300,
                  width: '100%',
                }}
              >
                <Reorder.Item
                  value={el}
                  style={{
                    cursor: 'pointer',
                    fontSize: 14,
                    display: 'flex',
                    rowGap: 5,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    columnGap: 15,
                    borderRadius: '5px',
                    backgroundColor: '#FFFFFF',
                    padding: 5,
                    maxWidth: 380,
                    width: '100%',
                  }}
                >
                  <span>
                    {index + 1}) {el.label}
                  </span>

                  <Button
                    danger
                    type='primary'
                    onClick={(e) => {
                      e.preventDefault()
                      setSoftwareList((oldValue) =>
                        oldValue.filter((item) => item.value !== el.value),
                      )
                    }}
                  >
                    x
                  </Button>
                </Reorder.Item>
              </div>
            )
          })}
        </Reorder.Group>
      )}

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
      >
        <Button type='primary' htmlType='submit' size='large'>
          Добавить
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PackageForm
