import moment from 'moment/moment'
import { useEffect, useRef, useState } from 'react'
import { AppStore } from '../store/store'

export const convertDateWithMoment = (date) => {
  return moment(date).format('DD.MM.YYYY, H:mm:ss')
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const useSendNotifications = {
  success: (successText) => {
    try {
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = successText
        s.notifications.type = 'success'
      })
    } catch (e) {
      AppStore.update((s) => {
        s.notifications.title = 'Успешно'
        s.notifications.text = 'Запрос прошел успешно'
        s.notifications.type = 'success'
      })
    }
  },

  error: (error) => {
    try {
      const errorText = error.response.data.error.comment

      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = errorText
        s.notifications.type = 'error'
      })
    } catch (e) {
      AppStore.update((s) => {
        s.notifications.title = 'Ошибка'
        s.notifications.text = 'Запрос прошел неуспешно'
        s.notifications.type = 'error'
      })
    }
  },
}

export function useHorizontalScroll() {
  const elRef = useRef()
  useEffect(() => {
    const el = elRef.current
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return
        e.preventDefault()
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth',
        })
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])
  return elRef
}
