import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AppStore } from '../../store/store'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'
import { useSendNotifications } from '../../utils/hooks'
import TableComponent from '../../components/tableComponent/tableComponent'
import { licenseColumns } from '../../columns/license'
import LicenseForm from '../../components/licenseForm'
import { agentTabs } from '../../constans/tabs/licenseTabs'
import LicenseInfo from '../../components/licenseInfo'

const License = () => {
  const queryClient = useQueryClient()

  const offset = AppStore.useState((s) => s.table.offset)

  const limit = AppStore.useState((s) => s.table.limit)

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const { data: licenseList, isLoading } = useQuery(['license_list', offset, limit], () =>
    apiRequest(routesList.license).get(offset, limit, { order_by: '-id' }),
  )

  const modifiedLicenseList = licenseList?.items?.map((el) => ({ ...el, key: el.id }))

  const { mutate: deleteUser } = useMutation(['delete_license'], {
    mutationFn: (id) => apiRequest(routesList.license).delete(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries('license_list')
      useSendNotifications.success('Лицензия успешно удалена')
    },
    onError: (error) => {
      useSendNotifications.error(error)
    },
  })

  const [licenseKey, setLicenseKey] = useState('')

  const { mutate: getKeyLicense } = useMutation({
    mutationKey: ['get_license_key'],
    mutationFn: (id) => apiRequest(`${routesList.license}/${id}/key`).get(),
    onSuccess: async (data) => {
      if (data) {
        await navigator.clipboard.writeText(data.license_key)
        setLicenseKey(data.license_key)
        useSendNotifications.success('Ключ успешно получен и скопирован в буфер')
      }
    },
    onError: async (error) => {
      useSendNotifications.error(error)
    },
  })

  return (
    <TableComponent
      title='Лицензии'
      data={modifiedLicenseList}
      total={licenseList?.total}
      columns={licenseColumns(deleteUser, getKeyLicense)}
      isLoading={isLoading}
      limit={limit}
      isDrawerVisible={isDrawerVisible}
      setIsDrawerVisible={setIsDrawerVisible}
      tabs={agentTabs}
      form={
        <LicenseForm
          closeDrawer={() => {
            setIsDrawerVisible(false)
          }}
        />
      }
      tabContent={(id) => {
        return (
          <LicenseInfo id={id} licenseKey={licenseKey} getKeyLicense={getKeyLicense} />
        )
      }}
    />
  )
}

export default License
