import React from 'react'
import { Card, List, Skeleton, Typography } from 'antd'
import s from '../../pages/units/units.module.css'
import clientLogo from '../../assets/images/client-logo-placeholder.png'
import Meta from 'antd/es/card/Meta'
import { TeamOutlined } from '@ant-design/icons'
import { useQuery } from 'react-query'
import { apiRequest } from '../../server/ApiClient'
import routesList from '../../server/routesList.json'

const ClientsOnUnitsPage = ({
  setId,
  setActiveUnitID,
  setSoftFormPackages,
  setIsVisible,
  client_id,
}) => {
  const { Title } = Typography

  const { data: clients, isLoading: isClientsLoading } = useQuery(['clients'], () =>
    apiRequest(routesList.clients).get('', '', {
      order_by: '-id',
    }),
  )

  const clientItems = clients?.items?.map((el) => {
    return (
      <List.Item key={el.id} style={{ height: '100%' }}>
        <Card
          className={s.cardInfo}
          onClick={() => {
            setActiveUnitID(null)
            setId((oldValue) => ({ ...oldValue, client_id: el.id }))
            setSoftFormPackages([])
            setIsVisible((oldValue) => ({
              products: true,
              units: false,
              packages: false,
              soft: false,
              softItem: false,
            }))
          }}
          style={{
            backgroundColor: client_id === el.id ? '#c1c5cb' : '',
          }}
          bodyStyle={{ marginBottom: 15 }}
          cover={
            <img
              className={s.logo}
              src={`${el.avatar ? el.avatar : clientLogo}`}
              alt='лого компании'
              style={{ padding: 15 }}
            />
          }
        >
          <Meta title={el.name} description={el.description} />
        </Card>
      </List.Item>
    )
  })

  return (
    <div className='units__box'>
      <Title style={{ color: '#505962' }} level={2}>
        <TeamOutlined /> Клиенты
      </Title>

      {isClientsLoading && <Skeleton active />}

      {!isClientsLoading && (
        <List
          grid={{ gutter: [4, 16], xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 4 }}
          dataSource={clientItems}
          renderItem={(item) => item}
        />
      )}
    </div>
  )
}

export default ClientsOnUnitsPage
